import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import { ToastContainer } from "react-toastify";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import AuthProvider, {
  AuthIsSignedIn,
  AuthIsNotSignedIn,
} from "./contexts/authContext";
import ScrollToTop from "./Components/ScrollToTop";
import HttpsRedirect from "react-https-redirect";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <ScrollToTop>
          <HttpsRedirect>
            <App />
          </HttpsRedirect>
          <ToastContainer />
        </ScrollToTop>
      </AuthProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
