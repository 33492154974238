import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../Components/Layout';
import { Helmet } from 'react-helmet';
import Separation from '../../assets/images/law-sepration.jpeg';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Footer from '../../Components/Footer';
import { CommonButton, HeroSection } from '../About';
import { ToggleTabs } from '../../Components/PrelegateModule/Prelegate/Prelegate';
import axios from 'axios';
import { convertDateFormat } from './blogsArticle';

const Blogs = (props) => {
  const history = useHistory();

  // useEffect(() => {
  //   window.scroll(0, 0);
  // }, []);

  // ... Remove previous meta description code ...

  const [currentPage, setCurrentPage] = useState(1); // Initialize currentPage to 1
  const [totalPages, setTotalPages] = useState(1);
  const [blogData, setBlogData] = useState([]);
  const itemsPerPage = 9; // Set the number of items to display per page
  const [skiped, setSkip] = React.useState(0);
  const [filteredValues, setFilteredValues] = React.useState([]);


  useEffect(() => {
    // Fetch data from your API based on the currentPage
    fetchData(currentPage);
  }, [currentPage]); // Trigger the effect whenever currentPage changes

  // Simulated API fetch function
  const fetchData = async (page) => {
    try {
      const skip = (page - 1) * itemsPerPage;
      setSkip(skip)
      // Replace this with your actual API endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/blogs?$limit=${itemsPerPage}&$skip=${skip}`);
      if (response.ok) {
        const data = await response.json();
        setBlogData(data.data); // Assuming your API response has a "data" property
        setFilteredValues(data.data); // Assuming your API response has a "data" property
        // console.log(data.total / itemsPerPage)
        setTotalPages(Math.ceil(data.total / itemsPerPage)); // Total number of pages from the API
      } else {
        // Handle API error
        console.error('Error fetching data:', response.status);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page); // Update currentPage when the user changes the page
  };

  const cardClick = (prelegateServiceName) => {
    history.push(`/articles/${prelegateServiceName}`);
  };
  const [selectedStateValue, setselectedStateValue] = useState(0)
  async function handleCategorizedArray(val) {
    try {
      // const response = await ax(`${process.env.REACT_APP_API_URL}/blogs?$limit=${itemsPerPage}&$skip=${skiped}&category=${val}`);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`, {
        params: {
          $limit: `${itemsPerPage}`,
          $skip: `${skiped}`,
          ...(val === 'All' ? null : { category: `${val}` })
        }
      })
      setFilteredValues(response?.data?.data)
    } catch (error) {
      console.log({ error })
    }
  }
  function removeSplice(str) {
    const startIndex = str.indexOf('>') + 1;

    // Find the index of the closing '</p>' tag
    const endIndex = str.lastIndexOf('<');

    // Remove <p> tags using slice
    const textWithoutPTags = str.slice(startIndex, endIndex);
    return textWithoutPTags;

  }
  function searchFilter(e) {
    let temp = [...blogData]
    if (!e.target.value || e.target.value === '') {
      setFilteredValues([...temp])
      return
    }
    let filteredValuess = temp.filter((it) => it.title.toLowerCase().includes(e.target.value.toLowerCase()))
    setFilteredValues([...filteredValuess])

  }
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const later = () => {
        timeout = null;
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  // how to use it
  const debouncedSearchCall = debounce(
    (value) => searchFilter(value),
    600 // 300ms delay
  );
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://legateca.com/blogs" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta
          name="description"
          content="Legateca is a leading legal advice firm, offering a full range of legal services to clients. We specialize in corporate and commercial law, and dispute resolution."
        ></meta>

      </Helmet>
      <HeroSection
        heading={'Articles'} bgImg={'/articles.png'}
      />
      <main className='xl:py-[100px] py-[70px] w-full mx-auto xl:px-0 md:px-8 px-6 xl:max-w-[1240px] 3xl:max-w-[2400px]'>
        <section className='w-full xl:flex-row flex-col-reverse gap-[30px] flex xl:justify-between'>
          <div className='flex flex-col gap-10 items-start'>
            {!filteredValues.length ?
              <div className='xl:min-w-[700px] flex justify-center text-[20px] font-[500] flex-col h-full items-center'>
                No Results To Show
              </div>
              :
              filteredValues.map((it, id) => {
                return <div key={id} onClick={() => window.location.replace(`/articles/${it.slug}`)} className='flex cursor-pointer flex-col gap-[35px] items-start w-full h-full xl:min-w-[820px] xl:max-w-[820px]'>
                  <img src={it?.logoimage} alt='ss' className='w-full min-h-[450px]  max-h-[450px]' />
                  <div className='flex flex-col w-full items-start gap-4'>
                    <div className='text-[rgba(0,0,0,0.34)] text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase'>
                      {it.title}
                    </div>
                    <div className='text-[#252525] text-[22px] not-italic font-semibold leading-[normal] tracking-[0.44px] capitalize'>
                      {it.title || 'Should I try Alternative Dispute Resolution before going to court?'}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: it.description }} className='text-[#252525] customsss text-sm not-italic font-normal webkit-custom leading-[142.014%] tracking-[0.28px]'>
                    
                      
                    </div>
                    <div className='flex items-center w-full justify-between'>
                      <div className='flex xl:flex-row flex-col gap-3 xl:gap-[22px] xl:items-center'>
                        <div className='flex gap-2 items-center text-[#00000057] '>
                          <svg width={13} height={15} viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.65039 8.36914C10.3027 8.36914 12.4629 10.5293 12.4629 13.1816C12.4629 13.6738 12.0527 14.0566 11.5879 14.0566H1.08789C0.595703 14.0566 0.212891 13.6738 0.212891 13.1816C0.212891 10.5293 2.3457 8.36914 5.02539 8.36914H7.65039ZM1.52539 12.7441H11.123C10.9043 11.0215 9.42773 9.68164 7.65039 9.68164H5.02539C3.2207 9.68164 1.74414 11.0215 1.52539 12.7441ZM6.33789 7.05664C4.39648 7.05664 2.83789 5.49805 2.83789 3.55664C2.83789 1.64258 4.39648 0.0566406 6.33789 0.0566406C8.25195 0.0566406 9.83789 1.64258 9.83789 3.55664C9.83789 5.49805 8.25195 7.05664 6.33789 7.05664ZM6.33789 1.36914C5.10742 1.36914 4.15039 2.35352 4.15039 3.55664C4.15039 4.78711 5.10742 5.74414 6.33789 5.74414C7.54102 5.74414 8.52539 4.78711 8.52539 3.55664C8.52539 2.35352 7.54102 1.36914 6.33789 1.36914Z" fill="currentColor" />
                          </svg>
                          <span className='text-inherit text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase'> {!it.author ? 'N/A' : it?.author}</span>
                        </div>
                        <div className='flex gap-2 items-center text-[#00000057] '>
                          <svg width={13} height={15} viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.40039 7.05664C6.61914 7.05664 6.83789 7.27539 6.83789 7.49414V10.1191C6.83789 10.3652 6.61914 10.5566 6.40039 10.5566H3.77539C3.5293 10.5566 3.33789 10.3652 3.33789 10.1191V7.49414C3.33789 7.27539 3.5293 7.05664 3.77539 7.05664H6.40039ZM4.21289 0.0566406C4.56836 0.0566406 4.86914 0.357422 4.86914 0.712891V1.80664H8.80664V0.712891C8.80664 0.357422 9.08008 0.0566406 9.46289 0.0566406C9.81836 0.0566406 10.1191 0.357422 10.1191 0.712891V1.80664H11.2129C12.1699 1.80664 12.9629 2.59961 12.9629 3.55664V12.3066C12.9629 13.291 12.1699 14.0566 11.2129 14.0566H2.46289C1.47852 14.0566 0.712891 13.291 0.712891 12.3066V3.55664C0.712891 2.59961 1.47852 1.80664 2.46289 1.80664H3.55664V0.712891C3.55664 0.357422 3.83008 0.0566406 4.21289 0.0566406ZM11.6504 5.30664H2.02539V12.3066C2.02539 12.5527 2.2168 12.7441 2.46289 12.7441H11.2129C11.4316 12.7441 11.6504 12.5527 11.6504 12.3066V5.30664Z" fill="currentColor" />
                          </svg>
                          <span className='text-inherit text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase'>{convertDateFormat(it.createdAt)}</span>
                        </div>
                      </div>
                      <div className='text-[#006766] whitespace-nowrap text-xs not-italic font-semibold leading-[45px] tracking-[0.24px] uppercase'>
                        <span>READ MORE</span>
                        <svg className='inline ml-2' width={13} height={2} viewBox="0 0 13 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.2129 0.806641C12.2129 1.13477 11.9551 1.36914 11.6504 1.36914H0.775391C0.447266 1.36914 0.212891 1.13477 0.212891 0.806641C0.212891 0.501953 0.447266 0.244141 0.775391 0.244141H11.6504C11.9551 0.244141 12.2129 0.501953 12.2129 0.806641Z" fill="#006766" />
                        </svg>
                      </div>

                    </div>
                  </div>
                </div>
              })
            }
          </div>




          <div className='w-full h-full xl:max-w-[358px] flex flex-col gap-[30px]'>
            <div className='border flex items-center border-[#D9D9D9] rounded-[10px] pl-4'>
              <input onChange={(e) => {
                debouncedSearchCall(e)
              }} className='bg-white !border-none outline-none focus:!border-none' placeholder='Search' />
              <div className='px-[14px] py-3 border-[#D9D9D9] border-l flex justify-content-center items-center'>
                <svg width={13} height={13} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.4648 11.1904C12.6992 11.4248 12.6992 11.7764 12.4648 11.9873C12.3711 12.1045 12.2305 12.1514 12.0898 12.1514C11.9258 12.1514 11.7852 12.1045 11.668 11.9873L8.52734 8.84668C7.68359 9.52637 6.62891 9.90137 5.50391 9.90137C2.83203 9.90137 0.652344 7.72168 0.652344 5.02637C0.652344 2.35449 2.80859 0.151367 5.50391 0.151367C8.17578 0.151367 10.3789 2.35449 10.3789 5.02637C10.3789 6.1748 10.0039 7.22949 9.32422 8.0498L12.4648 11.1904ZM1.77734 5.02637C1.77734 7.1123 3.44141 8.77637 5.52734 8.77637C7.58984 8.77637 9.27734 7.1123 9.27734 5.02637C9.27734 2.96387 7.58984 1.27637 5.52734 1.27637C3.44141 1.27637 1.77734 2.96387 1.77734 5.02637Z" fill="#006766" />
                </svg>
              </div>
            </div>

            <div className='flex flex-col gap-4'>
              <div className='text-[22px] font-semibold text-ThemeSecondary uppercase'>categories </div>
              <div className='cursor-pointer'>
                {['All', 'Business', 'Disputes', 'Employment', 'Family', 'Immigration', 'Property'].map((it, id) => {
                  return <div onClick={() => {
                    handleCategorizedArray(it)
                    setselectedStateValue(id)
                  }} key={id} className={`py-[20px] !cursor-pointer ${selectedStateValue == id ? 'bg-ThemeSecondary text-base text-white font-[500] ' : 'border-b border-[#BBBBBB] text-base text-[#252525]'} pl-[10px] `}>{it}</div>
                })}
              </div>

            </div>
            <div className='flex flex-col gap-4'>
              <div className='text-[22px] font-semibold text-ThemeSecondary uppercase'>recent articles </div>
              <div className='flex flex-col gap-[30px]'>
                {filteredValues?.map((it, id) => {
                  // if (it._id === productData?._id) return
                  return <div key={id} onClick={() => window.location.replace(`/articles/${it.slug}`)} className='flex gap-[10px] cursor-pointer'>
                    <img src={it.logoimage} className='w-[62px] h-[62px] ' alt='blogs' />
                    <div className='xl:min-w-[286px] xl:max-w-[286px] w-full'>
                      <div>{it.title}</div>
                      <div className='flex items-center xl:pr-[30px] w-full justify-between'>
                        <div className='flex gap-2 items-center text-[#00000057] '>
                          <svg width={13} height={15} viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.40039 7.05664C6.61914 7.05664 6.83789 7.27539 6.83789 7.49414V10.1191C6.83789 10.3652 6.61914 10.5566 6.40039 10.5566H3.77539C3.5293 10.5566 3.33789 10.3652 3.33789 10.1191V7.49414C3.33789 7.27539 3.5293 7.05664 3.77539 7.05664H6.40039ZM4.21289 0.0566406C4.56836 0.0566406 4.86914 0.357422 4.86914 0.712891V1.80664H8.80664V0.712891C8.80664 0.357422 9.08008 0.0566406 9.46289 0.0566406C9.81836 0.0566406 10.1191 0.357422 10.1191 0.712891V1.80664H11.2129C12.1699 1.80664 12.9629 2.59961 12.9629 3.55664V12.3066C12.9629 13.291 12.1699 14.0566 11.2129 14.0566H2.46289C1.47852 14.0566 0.712891 13.291 0.712891 12.3066V3.55664C0.712891 2.59961 1.47852 1.80664 2.46289 1.80664H3.55664V0.712891C3.55664 0.357422 3.83008 0.0566406 4.21289 0.0566406ZM11.6504 5.30664H2.02539V12.3066C2.02539 12.5527 2.2168 12.7441 2.46289 12.7441H11.2129C11.4316 12.7441 11.6504 12.5527 11.6504 12.3066V5.30664Z" fill="currentColor" />
                          </svg>
                          <span className='text-inherit text-sm not-italic font-medium tracking-[0.28px] uppercase'>{convertDateFormat(it.createdAt)}</span>
                        </div>
                        <div className='text-[#006766] text-xs not-italic font-semibold  tracking-[0.24px] uppercase'>
                          <span>VIEW</span>
                          <svg className='inline ml-2' width={13} height={2} viewBox="0 0 13 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2129 0.806641C12.2129 1.13477 11.9551 1.36914 11.6504 1.36914H0.775391C0.447266 1.36914 0.212891 1.13477 0.212891 0.806641C0.212891 0.501953 0.447266 0.244141 0.775391 0.244141H11.6504C11.9551 0.244141 12.2129 0.501953 12.2129 0.806641Z" fill="#006766" />
                          </svg>
                        </div>

                      </div>
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      {/* <Layout>
        <div>
          <Helmet>
            <link rel="canonical" href="https://legateca.com/blogs" />
            <meta name="robots" content="INDEX,FOLLOW" />
          </Helmet>
        </div>
        <section className="page-breadcumb-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="wpo-breadcumb-wrap">
                  <h2>Articles</h2>
                  <ul>
                    <li>
                      <Link to="/home">Home</Link>
                    </li>
                    <li>
                      <span>&nbsp; / &nbsp;</span>
                      <span>Articles</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sec-categories">
          <section className="servicesInner-page content_area">
            <div className="container">
              <div className="servicesList-section row">
                {filteredValues.map((item, idx) => (
                  <div className="col-12 col-md-4 mb-3" key={idx}>
                    <div
                      key={item._id}
                      id={item._id}
                      onClick={() => cardClick(item.slug)}
                    >
                      <div className="lawService-card">
                        <div className="cardPicture">
                          <img
                            src={item.logoimage ? item.logoimage : Separation}
                            alt="services images"
                            width="150"
                            height="200"
                          />
                          <div className="card-content">
                            <h3> {item.title} </h3>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="secondary"
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    '& .MuiPaginationItem-root': {
                      color: 'black',
                    },
                    '& .MuiPaginationItem-page.Mui-selected': {
                      backgroundColor: '#006766',
                      color: 'white',
                    },
                    '& .MuiPagination-ul': {
                      justifyContent: 'center', // Center-align the pagination buttons
                    },
                    '& .MuiPaginationItem-page': {
                      marginRight: '8px', // Add spacing between buttons
                    },
                  }}

                />
              </Stack>
            </div>
          </section>
        </div>
      </Layout> */}
    </>
  );
};

export default Blogs;
