import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoginAuthFirst from "../Components/Modals/LoginAuth";
import { Markup } from "interweave";
import Footer from "../Components/Footer";
import { GoogleReviews, HeroSection, HeroSectionCard } from "./About";
import { InputCommon, legatecaLogo, variant } from "./Partner";
import legatecaLogoo from "../assets/images/legatecaFirstLogo.svg";
import ImageWithText from "../Components/ReUseAble/ImageWithText";
import whoWeAreImg from "../assets/images/who-we-are-img.png";
import services from "../server/services";
import { toast } from "react-toastify";
import SiaraWrapper from "../Components/SiaraWrapper";
import { CommonButtonWOLink } from "../Components/LawyemModule/Lawyem/Lawyem";
// import { Helmet } from "react-helmet-async";

const SummaryPage = (props) => {
  var currentURL = window.location.href;
  var splitURL = currentURL.toString().split("/");
  var URL = `${process.env.REACT_APP_API_URL}/categories?service=${splitURL[3]}&name=${splitURL[4]}`;
  const [loginFirst, setloginFirst] = useState("Closed");
  const [userData, setUserData] = useState(null);
  const [questionaireValue, setquestionaireValue] = useState(null);

  useEffect(() => {
    setUserData(props.user);
  }, [props.user]);

  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProductdata();
  }, []);

  async function getProductdata() {
    const request = await fetch(URL)
      .then((request) => request.json())
      .then((data) => {
        localStorage.setItem("serviceData", JSON.stringify(data));
        setProductData(data);
        setLoading(false); // set Loading to false when you have the data
      })
      .catch((err) => console.log(err));
  }
  const [hoverFirst, sethoverFirst] = useState(true);
  const [toggleExpansion, settoggleExpansion] = useState(0);
  let initialState = {
    name: "",
    email: "",
    phone: "",
    // subject: "",
    note: "",
  };
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const addValidateInfo = (value) => {
    let errors = {};

    if (!value.name) {
      errors.name = "Name Required";
    } else if (value.name.trim().length === 0) {
      errors.name = "Name Required";
    }
    if (!value.email) {
      errors.email = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
      errors.email = "Email address is invalid";
    }

    if (!/^\+?\d+$/.test(value.phone)) {
      errors.phone = "Contact Number Must be a Number";
    }
    if (!value.note) {
      errors.note = "Message Required";
    }

    return errors;
  };
  let jsondata = "";
  let pageSummary,
    pageImage,
    pageName,
    _id,
    question_URL,
    canonicalLink,
    metaDescription,
    metaKeywords,
    metaTitle;

  let pageData = JSON.parse(localStorage.getItem("serviceData"));
  try {
    pageName = pageData.data[0].name;
    pageSummary = pageData.data[0].pageSummary;
    canonicalLink = pageData.data[0].canonicalLink;
    metaDescription = pageData.data[0].metaDescription;
    metaKeywords = pageData.data[0].metaKeywords;
    metaTitle = pageData.data[0].metaTitle;
    pageImage = pageData.data[0].image;
    _id = pageData.data[0]._id;
    question_URL = window.location.origin + `/service/prelegate/${_id}`;
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }

  const buttonClick = (serviceName, id) => {
    let signUpCheck = JSON.parse(sessionStorage.getItem("state"));
    if (signUpCheck["auth"]["isSignedIn"] == true) {
      window.location.replace(`/service/${serviceName}/${id}`);
    } else {
      setloginFirst("Open");
    }
  };

  //setloginFirst('Open');
  const hideLoginModal = () => {
    setloginFirst("Closed");
  };

  try {
    let prevMetaDesc = document.getElementById("baseDescription");
    prevMetaDesc.remove();
    //use the piece of loading state to return other component until you have the data
  } catch (error) {}

  const reviews = [
    {
      text: "Excellent service from the team from Legateca, especially Zakia who gave me some invaluable help",
      name: "Chris Raywood",
      date: "7 Months ago",
    },
    {
      text: "Amazing and professional work from Zakia, strongly recommend this company for their services",
      name: "Wendy",
      date: "9 Months ago",
    },
    {
      text: "Very promt, accessible almost anytime, reliable, and more importantly professional with a price that will not break your bank",
      name: "Karim Younas",
      date: "Year ago",
    },
    {
      text: "We have received an excellent service from the team at Legateca. Our business immigration case is being handled by an experienced solicitor, who understood our requirements, provided us with options and has been very supportive throughout. We would highly recommend the service.",
      name: "Huang Kifi",
      date: "Year Ago",
    },
  ];
  let serviceData = productData?.data[0]?.pageSummary;

  let propsForImages = {
    showKnowMore: false,
    image: serviceData?.section1?.img,
    legatecafirstlogo: legatecaLogoo,
  };
  let propsForImages2 = {
    showKnowMore: false,
    image: serviceData?.section7?.img,
    legatecafirstlogo: legatecaLogoo,
  };
  let screens =
    "xl:max-w-[1240px] 2xl:max-w-[1630px] 3xl:max-w-[2400px] mx-auto";

  let cardSvgs = ["/swap1.png", "/idea1.png", "/tap1.png", "/agreement1.png"];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const validate = (e) => {
    e.preventDefault();

    let verifyToken = localStorage.getItem("verifyToken");

    if (verifyToken) {
      return "";
    }

    const validationResult = addValidateInfo(values);
    if (Object.keys(validationResult).length > 0) {
      setErrors(validationResult);
    } else {
      let data = {
        name: values?.name,
        email: values?.email,
        phoneNo: values?.phone,
        message: values?.note,
        type: "contact",
      };

      services["contact-us"].create(data);
      toast.success("We will get in touch with you soon", {
        hideProgressBar: true,
        autoClose: 2000,
      });
      setErrors({});
      setValues(initialState);
    }
  };
  useEffect(() => {
    const getQuestionList = async () => {
      try {
        if (localStorage.getItem("idToken")) {
          let res = await services["questions"].find({
            query: {
              category: productData?.data[0]._id,
              service: "prelegate",
              $sort: {
                createdAt: 1,
              },
              $limit: 1000,
              $skip: 0,
            },
          });
          if (res.value.data.length > 0) {
            setquestionaireValue(false);
            return;
          }
          setquestionaireValue(true);
        } else {
          // toast.error("Session expired. Please login again.", {
          //   hideProgressBar: true,
          //   autoClose: 2000,
          // });
          // props.history.push({
          //   pathname: "/login",
          //   state: window.location.pathname,
          // });
        }
      } catch (error) {}
    };
    if (!productData?.data[0]) return;

    getQuestionList();
  }, [productData?.data, props.history]);
  if (loading) {
    return (
      <>
        {/* <HeroSection
        heading={productData?.data[0]?.canonicalLink} bgImg={'/services.png'}
      /> */}
        <div className="w-full h-screen flex flex-col justify-center items-center">
          <div class="loader-loader"></div>
        </div>
        {/* <Footer /> */}
      </>
    );
    // return <div>Loading...</div>
  }
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <link rel="canonical" href={canonicalLink} />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta name="description" content={metaDescription}></meta>
        <meta name="keywords" content={metaKeywords}></meta>
      </Helmet>
      <HeroSection
        heading={
          productData?.data[0]?.name.includes("undefined") ||
          !productData?.data[0]?.name
            ? "N/A"
            : productData?.data[0]?.name
        }
        bgImg={"/services.png"}
      />
      <main className="flex w-full flex-col xl:gap-[100px] gap-[70px] xl:py-[100px] py-[70px] xl:!px-0  !px-6 ">
        {!productData?.data[0]?.canonicalLink && (
          <div
            className={` h-full w-full gap-[35px] flex xl:flex-row flex-col  justify-center`}
          >
            <div className="summaryContainer">
              <div className="text-black text-[40px]">Results Not Found</div>
            </div>
          </div>
        )}

        {typeof serviceData === "string" ? (
          <>
            {!productData?.data[0]?.canonicalLink.includes("undefined") && (
              <div className={`flex flex-col gap-10 ${screens} p-6`}>
                <div
                  className={` h-full w-full gap-[35px] flex xl:flex-row flex-col  justify-center`}
                >
                  <div className="summaryContainer">
                    <div dangerouslySetInnerHTML={{ __html: pageSummary }} />
                  </div>
                </div>
                <div className={`gap-3 flex flex-col items-center  `}>
                  <CommonButtonWOLink
                    disabled={questionaireValue}
                    variantVal={"primary"}
                    label={"Questionaire"}
                    handleClick={() => buttonClick("prelegate", _id)}
                  />
                  {questionaireValue && (
                    <div className={`text-center text-xl font-[500] `}>
                      No questions added yet
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {serviceData?.section1?.enabled && (
              <div className={`${screens} w-full`}>
                <ImageWithText
                  {...propsForImages}
                  title={serviceData?.section1?.head1}
                  to={"/about"}
                  backHidden="false"
                  btnVisibility={true}
                  size={
                    "xl:min-w-[624px] xl:max-w-[624px] max-h-[440px] min-h-[440px]"
                  }
                  alt={
                    serviceData?.section1?.alt
                      ? serviceData?.section1?.alt
                      : "nill"
                  }
                  heading={serviceData?.section1?.head2}
                  paragraph={
                    <div className=" text-values-align  not-italic font-normal ">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: serviceData?.section1?.description,
                        }}
                        className="!text-[#252525] whitespace-pre-line 3xl:text-[36px] lg:text-sm text-base 3xl:!leading-[130%] lg:!leading-[26px] leading-[34px] tracking-[0.28px] "
                      ></p>
                    </div>
                  }
                />
              </div>
            )}
            {serviceData?.section2?.enabled && (
              <section
                style={{
                  background: "rgba(0, 103, 102, .06)",
                }}
                className={`h-full w-full xl:py-[62px] `}
              >
                <div
                  className={`${screens} h-full w-full gap-[35px] flex xl:flex-row flex-col p-6 justify-center`}
                >
                  <div className="xl:max-w-[582px] xl:min-w-[582px]  flex flex-col gap-[16px]">
                    <div>
                      <h2 className="text-[#E3B576]  lg:text-sm 3xl:text-[30px] 3xl:!leading-[130%]  text-base not-italic font-medium !leading-[45px] tracking-[0.28px] uppercase">
                        {serviceData?.section2?.head1}
                      </h2>
                      <h2 className="text-[#006766] xl:max-w-[960px] mx-auto text-[32px] 3xl:text-[60px] 3xl:!leading-[130%] lg:text-[42px] not-italic lg:font-medium font-semibold lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase">
                        {serviceData?.section2?.head2}
                      </h2>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: serviceData?.section2?.description,
                      }}
                      className="!text-[#252525] lg:text-sm text-base whitespace-pre-line 3xl:text-[30px] 3xl:!leading-[130%] 3xl:mb-10 not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]"
                    ></p>
                    <div className="flex flex-col gap-3 xl:max-h-[262px] overflow-y-auto">
                      {serviceData?.section2?.points?.map((it, id) => {
                        return (
                          <div key={id} className="flex items-start gap-3">
                            <svg
                              className={`min-w-[23px] max-w-[23px] min-h-[23px] max-h-[23px]`}
                              viewBox="0 0 16 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.6289 0.745117C15.0352 1.12012 15.0352 1.77637 14.6289 2.15137L6.62891 10.1514C6.25391 10.5576 5.59766 10.5576 5.22266 10.1514L1.22266 6.15137C0.816406 5.77637 0.816406 5.12012 1.22266 4.74512C1.59766 4.33887 2.25391 4.33887 2.62891 4.74512L5.91016 8.02637L13.2227 0.745117C13.5977 0.338867 14.2539 0.338867 14.6289 0.745117Z"
                                fill="#006766"
                              />
                            </svg>
                            <span className="text-[#252525] text-sm not-italic font-normal leading-[normal] tracking-[0.28px]">
                              {it}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="xl:min-w-[625px] xl:block hidden xl:min-h-[694px] xl:max-w-[625px] xl:max-h-[694px]">
                    <img
                      className="w-full h-full object-cover"
                      src={serviceData?.section2?.img}
                      alt={
                        serviceData?.section2?.alt
                          ? serviceData?.section2?.alt
                          : "nill"
                      }
                    />
                  </div>
                </div>
              </section>
            )}
            {serviceData?.section3?.enabled && (
              <section className={`h-full w-full xl:py-[62px] `}>
                <div
                  className={`${screens} h-full w-full gap-[35px] flex xl:flex-row flex-col p-6 justify-center`}
                >
                  <div className="xl:max-w-[590px] w-full flex flex-col gap-[16px]">
                    <div>
                      <h2 className="text-[#E3B576]  lg:text-sm 3xl:text-[30px] 3xl:!leading-[130%]  text-base not-italic font-medium !leading-[45px] tracking-[0.28px] uppercase">
                        {serviceData?.section3?.head1}
                      </h2>
                      <h2 className="text-[#006766] xl:max-w-[960px] mx-auto text-[32px] 3xl:text-[60px] 3xl:!leading-[130%] lg:text-[42px] not-italic lg:font-medium font-semibold lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase">
                        {serviceData?.section3?.head2}
                      </h2>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: serviceData?.section3?.description,
                      }}
                      className="!text-[#252525] lg:text-sm text-base whitespace-pre-line 3xl:text-[30px] 3xl:!leading-[130%] 3xl:mb-10 not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]"
                    ></p>
                    <div className="w-full h-full xl:min-w-[550px] xl:min-h-[400px] xl:max-w-[550px] xl:max-h-[400px] hidden xl:block">
                      <img
                        src={serviceData?.section3?.img}
                        alt={
                          serviceData?.section3?.alt
                            ? serviceData?.section3?.alt
                            : "nill"
                        }
                        className="w-full h-full object-fill"
                      />
                    </div>
                  </div>
                  <div className="xl:min-w-[586px] xl:max-w-[586px] xl:pt-[118px] flex flex-col gap-3">
                    <div className="xl:max-w-[580px] xl:mt-[20px] xl:min-w-[580px] 3xl:max-w-[1100px] 3xl:min-w-[1100px]">
                      {serviceData?.section3?.pointsToggle?.map((it, id) => {
                        return (
                          <LegatecaLegaSection
                            heading={it.key}
                            id={id}
                            toggleExpansion={toggleExpansion}
                            valuesFortoggle={it.value}
                            settoggleExpansion={settoggleExpansion}
                          />
                        );
                      })}
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: serviceData?.section3?.description2,
                      }}
                      className="!text-[#252525] lg:text-sm text-base whitespace-pre-line 3xl:text-[30px] 3xl:!leading-[130%] 3xl:mb-10 not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]"
                    ></p>
                  </div>
                </div>
              </section>
            )}
            <section
              className={` mb-10 w-full mx-auto ${screens} text-center `}
            >
              {serviceData?.section4?.enabled && (
                <div className="w-full xl:px-0 padding-md ">
                  <h2 className="text-[#E3B576]  lg:text-sm 3xl:text-[30px] 3xl:!leading-[130%]  text-base not-italic font-medium !leading-[45px] tracking-[0.28px] uppercase">
                    {serviceData?.section4?.head1}
                  </h2>
                  <h2 className="text-[#006766] xl:max-w-[960px] mx-auto text-[32px] 3xl:text-[60px] 3xl:!leading-[130%] lg:text-[42px] not-italic lg:font-medium font-semibold lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase">
                    {serviceData?.section4?.head2}
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: serviceData?.section4?.description,
                    }}
                    className="!text-[#252525] whitespace-pre-line lg:text-sm text-base 3xl:text-[30px] 3xl:!leading-[130%] 3xl:mb-10 not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]"
                  ></p>
                </div>
              )}
              {serviceData?.section5?.enabled && (
                <>
                  <div
                    onMouseEnter={() => sethoverFirst(false)}
                    className="w-full xl:max-h-[300px] xl:min-h-[300px] 3xl:max-h-[500px] 3xl:min-h-[500px] xl:flex xl:gap-0 grid md:grid-cols-2 grid-cols-1 gap-y-5 xl:gap-x-0 gap-x-4 justify-between items-center"
                  >
                    {serviceData?.section5.cardSection?.map((ite, id) => {
                      return (
                        <>
                          <HeroSectionCard
                            key={id}
                            id={id}
                            hoverFirst={hoverFirst}
                            {...{
                              cardSvg: cardSvgs[id],
                              head: ite.head,
                              para: ite?.description,
                            }}
                            page="prelegate"
                            link={serviceData?.section5.link}
                          />
                        </>
                      );
                    })}
                  </div>
                  <div className="flex items-center justify-center">
                    <a
                      href={serviceData?.section5.link}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <CommonButtonWOLink
                        variantVal={"primary"}
                        label={"Book a Consultation"}
                      />
                    </a>
                  </div>
                </>
              )}
            </section>
            <section
              className={` w-full  relative  xl:flex flex-col justify-end min-h-[562px] max-h-[562px] 3xl:min-h-[922px] 3xl:max-h-[922px] `}
            >
              <div
                className={`bg-img w-full  h-full min-h-[612px] max-h-[612px] xl:min-h-[542px] xl:max-h-[542px] 3xl:min-h-[822px] 3xl:max-h-[822px] xl:!p-0 sm:!px-10 !px-3 pb-2 `}
              ></div>
              <div
                className={`w-full h-full  xl:max-h-[514px] flex justify-center  top-10 xl:top-0 3xl:top-10 absolute`}
              >
                <div
                  className={`${screens} max-lg:px-[20px] w-full mx-auto flex justify-center xl:gap-10 h-full items-center  xl:items-end`}
                >
                  <div>
                    <img
                      className="hidden xl:block xl:min-h-[514px] object-cover xl:max-h-[514px] xl:max-w-[580px] 3xl:min-h-[814px] 3xl:max-h-[814px] 3xl:max-w-[1200px] 3xl:min-w-[1200px]"
                      alt="Signature"
                      src="/formprelegate.png"
                    />
                  </div>
                  <form
                    method="post"
                    className="contact-validation-active"
                    id="contact-form-main"
                    novalidate="novalidate"
                    onSubmit={validate}
                  >
                    <div
                      className={` xl:max-w-[590px]  xl:px-0 3xl:max-w-[1100px] 3xl:min-w-[1100px] w-full flex flex-col gap-[10px] 3xl:gap-[20px]`}
                    >
                      <div
                        className={`text-values-align text-[32px] 3xl:text-[60px] lg:text-[42px] not-italic lg:font-medium font-semibold 3xl:!leading-[130%] lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase text-white`}
                      >
                        Alternatively, just send us a message
                      </div>
                      <div
                        className={`flex flex-col gap-[10px] 3xl:gap-[20px] mb-3`}
                      >
                        <div className="flex items-center  gap-7 w-full">
                          <InputCommon
                            error={
                              errors.name && (
                                <span className="error-message 3xl:text-[28px]">
                                  {errors.name}
                                </span>
                              )
                            }
                            value={values.name}
                            handleChange={handleChange}
                            name={"name"}
                            placeholder="Your Name"
                            svg={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-5"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M6 6C6.69223 6 7.36892 5.82405 7.9445 5.49441C8.52007 5.16477 8.96867 4.69623 9.23358 4.14805C9.49849 3.59987 9.5678 2.99667 9.43275 2.41473C9.2977 1.83279 8.96436 1.29824 8.47487 0.878681C7.98539 0.459123 7.36175 0.173401 6.68282 0.0576455C6.00388 -0.0581101 5.30015 0.00129985 4.66061 0.228363C4.02107 0.455426 3.47444 0.839943 3.08986 1.33329C2.70527 1.82664 2.5 2.40666 2.5 3C2.5 3.79565 2.86875 4.55871 3.52513 5.12132C4.1815 5.68393 5.07174 6 6 6ZM6 0.857144C6.49445 0.857144 6.9778 0.98282 7.38893 1.21828C7.80005 1.45374 8.12048 1.78841 8.3097 2.17996C8.49892 2.57152 8.54843 3.00238 8.45196 3.41805C8.3555 3.83372 8.1174 4.21555 7.76777 4.51523C7.41814 4.81491 6.97268 5.019 6.48773 5.10168C6.00277 5.18437 5.50011 5.14193 5.04329 4.97974C4.58648 4.81755 4.19603 4.5429 3.92133 4.19051C3.64662 3.83812 3.5 3.42382 3.5 3C3.5 2.43168 3.76339 1.88664 4.23223 1.48477C4.70107 1.08291 5.33696 0.857144 6 0.857144Z"
                                  fill="white"
                                />
                                <path
                                  d="M6.5 6.85712H5.5C4.04131 6.85712 2.64236 7.3538 1.61091 8.2379C0.579463 9.122 0 10.3211 0 11.5714C0 11.6851 0.0526785 11.7941 0.146447 11.8744C0.240215 11.9548 0.367392 12 0.5 12H11.5C11.6326 12 11.7598 11.9548 11.8536 11.8744C11.9473 11.7941 12 11.6851 12 11.5714C12 10.3211 11.4205 9.122 10.3891 8.2379C9.35764 7.3538 7.95869 6.85712 6.5 6.85712ZM1.03 11.1428C1.15295 10.2002 1.67676 9.32954 2.50134 8.69707C3.32592 8.0646 4.39347 7.71471 5.5 7.71426H6.5C7.60653 7.71471 8.67408 8.0646 9.49866 8.69707C10.3232 9.32954 10.8471 10.2002 10.97 11.1428H1.03Z"
                                  fill="white"
                                />
                              </svg>
                            }
                          />
                          <InputCommon
                            error={
                              errors.phone && (
                                <span className="error-message 3xl:text-[28px]">
                                  {errors.phone}
                                </span>
                              )
                            }
                            value={values.phone}
                            handleChange={handleChange}
                            name={"phone"}
                            placeholder="Your Phone Number"
                            svg={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-5"
                                viewBox="0 0 12 14"
                                fill="none"
                              >
                                <path
                                  d="M10.6159 14H10.5374C1.47155 13.3915 0.184328 4.46385 0.00439445 1.73923C-0.0101039 1.52739 0.0113559 1.31429 0.0675442 1.11214C0.123733 0.909999 0.213545 0.722779 0.331836 0.561213C0.450127 0.399646 0.594571 0.266909 0.756891 0.170606C0.919212 0.0743029 1.09622 0.0163271 1.27777 5.04857e-07H3.81991C4.00472 -0.000208291 4.18532 0.0643519 4.33832 0.185318C4.49132 0.306285 4.60968 0.478084 4.67806 0.678462L5.37934 2.69231C5.44686 2.88806 5.46362 3.10266 5.42753 3.30943C5.39144 3.51621 5.30409 3.70605 5.17634 3.85539L4.19362 5.01308C4.34713 6.03117 4.76488 6.96967 5.39036 7.70159C6.01585 8.43352 6.81887 8.92355 7.69079 9.10538L8.69196 7.94769C8.82184 7.80024 8.98603 7.70067 9.16407 7.66141C9.34211 7.62215 9.52612 7.64494 9.69313 7.72692L11.4325 8.54C11.6016 8.62232 11.7459 8.76154 11.8469 8.9399C11.948 9.11827 12.0013 9.32771 12 9.54154V12.3846C12 12.813 11.8542 13.2239 11.5946 13.5269C11.335 13.8298 10.983 14 10.6159 14ZM1.3885 1.07692C1.26614 1.07692 1.14879 1.13365 1.06226 1.23464C0.97574 1.33562 0.927132 1.47258 0.927132 1.61539V1.65846C1.13936 4.84615 2.5004 12.3846 10.5882 12.9231C10.6488 12.9274 10.7096 12.9178 10.767 12.8947C10.8244 12.8716 10.8773 12.8355 10.9228 12.7885C10.9682 12.7415 11.0052 12.6845 11.0317 12.6207C11.0583 12.5569 11.0737 12.4877 11.0772 12.4169V9.54154L9.33788 8.72846L8.01375 10.2631L7.7923 10.2308C3.77839 9.64385 3.23397 4.95923 3.23397 4.91077L3.20629 4.65231L4.51658 3.10692L3.82453 1.07692H1.3885Z"
                                  fill="white"
                                />
                              </svg>
                            }
                          />
                        </div>
                        <InputCommon
                          error={
                            errors.email && (
                              <span className="error-message 3xl:text-[28px]">
                                {errors.email}
                              </span>
                            )
                          }
                          value={values.email}
                          handleChange={handleChange}
                          name={"email"}
                          placeholder="Email Address"
                          svg={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-5"
                              viewBox="0 0 14 12"
                              fill="none"
                            >
                              <path
                                d="M12.25 0H1.75C0.783562 0 0 1.024 0 2.28571V9.71429C0 10.9766 0.783562 12 1.75 12H12.25C13.2164 12 14 10.9766 14 9.71429V2.28571C14 1.024 13.2164 0 12.25 0ZM0.875 3L3.93706 6L0.875 9V3ZM13.125 9.71429C13.125 10.3451 12.7321 10.8571 12.25 10.8571H1.75C1.26744 10.8571 0.875 10.3451 0.875 9.71429L4.3015 6.35714L6.2125 8.22971C6.44569 8.45714 6.72262 8.57257 7 8.57257C7.27694 8.57257 7.55387 8.45771 7.78706 8.22971L9.6985 6.35714L13.125 9.71429ZM13.125 9L10.0625 6L13.125 3V9ZM7.52456 7.77257C7.37187 7.92229 7.19075 8.00114 7 8.00114C6.80925 8.00114 6.62769 7.92171 6.475 7.77257L4.66594 6L4.3015 5.64286L0.875 2.28629V2.28571C0.875 1.65543 1.26744 1.14286 1.75 1.14286H12.25C12.7321 1.14286 13.125 1.65543 13.125 2.28571L7.52456 7.77257Z"
                                fill="white"
                              />
                            </svg>
                          }
                        />
                        <InputCommon
                          elem={true}
                          error={
                            errors.note && (
                              <span className="error-message 3xl:text-[28px]">
                                {errors.note}
                              </span>
                            )
                          }
                          value={values.note}
                          handleChange={handleChange}
                          name={"note"}
                          placeholder="Short Summary"
                          svg={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-5"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M12.3574 1.45846e-06H1.64037C1.20532 1.45846e-06 0.788082 0.172852 0.480453 0.480527C0.172824 0.788203 0 1.2055 0 1.64062L0 8.70294C0 9.13805 0.172824 9.55535 0.480453 9.86303C0.788082 10.1707 1.20532 10.3436 1.64037 10.3436H4.18622C4.21522 10.3436 4.24304 10.3551 4.26355 10.3756C4.28406 10.3961 4.29558 10.4239 4.29558 10.4529V13.2343C4.29557 13.3858 4.34046 13.5338 4.42457 13.6597C4.50869 13.7856 4.62825 13.8838 4.76813 13.9417C4.90801 13.9997 5.06194 14.0148 5.21044 13.9853C5.35894 13.9557 5.49535 13.8828 5.60241 13.7757L9.00125 10.3764C9.01147 10.3662 9.02359 10.3582 9.03691 10.3528C9.05023 10.3473 9.0645 10.3446 9.07889 10.3446H12.3596C12.7947 10.3446 13.2119 10.1718 13.5195 9.86412C13.8272 9.55645 14 9.13915 14 8.70403V1.64062C14 1.42499 13.9575 1.21147 13.8749 1.01228C13.7924 0.813085 13.6713 0.632128 13.5188 0.479753C13.3662 0.327379 13.1851 0.206578 12.9859 0.124258C12.7866 0.0419376 12.573 -0.000286053 12.3574 1.45846e-06ZM13.3417 8.70294C13.3417 8.96401 13.238 9.21439 13.0534 9.39899C12.8688 9.5836 12.6185 9.68731 12.3574 9.68731H9.07671C8.87446 9.68584 8.67985 9.76448 8.53539 9.90606L5.13982 13.3109C5.12453 13.3263 5.10498 13.3368 5.08369 13.3411C5.06239 13.3454 5.0403 13.3432 5.02024 13.3349C5.00018 13.3265 4.98305 13.3124 4.97105 13.2943C4.95904 13.2762 4.95269 13.255 4.95282 13.2332V10.4529C4.95282 10.2499 4.87217 10.0551 4.72861 9.91155C4.58505 9.76797 4.39034 9.68731 4.18731 9.68731H1.64037C1.37934 9.68731 1.129 9.5836 0.944419 9.39899C0.759842 9.21439 0.656147 8.96401 0.656147 8.70294V1.64062C0.656147 1.37955 0.759842 1.12917 0.944419 0.944564C1.129 0.759959 1.37934 0.656249 1.64037 0.656249H12.3574C12.6185 0.656249 12.8688 0.759959 13.0534 0.944564C13.238 1.12917 13.3417 1.37955 13.3417 1.64062V8.70294Z"
                                fill="white"
                              />
                            </svg>
                          }
                        />
                      </div>

                      <div className="flex justify-between sm:flex-row flex-col items-center gap-4 sm:gap-0  sm:items-end  ">
                        <SiaraWrapper>
                          <button
                            type={"submit"}
                            className={`min-w-[204px] max-w-[204px] 3xl:min-w-[350px] 3xl:max-w-[350px] flex ${variant(
                              "secondary-not-hovered"
                            )} px-[28px] py-[9px] 3xl:py-6 3xl:px-[32px]  items-center rounded-md 3xl:rounded-lg justify-between border-none`}
                          >
                            <img
                              src={legatecaLogoo}
                              alt="nill"
                              className="3xl:h-[66px] 3xl:w-[50px]"
                            />
                            <div className="text-white text-sm not-italic font-medium  tracking-[0.28px] uppercase font-montserrat  3xl:text-[30px] ">
                              Submit Now
                            </div>
                          </button>
                        </SiaraWrapper>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <style>
                {`
         .bg-img {
          position: relative;
          background-image: url('/partner-green-back.png');
          background-repeat: no-repeat;
          background-size: cover;
      }
      
      .bg-img::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: #006766CC; /* Specify your overlay color here */
          pointer-events: none; /* This ensures that the overlay doesn't interfere with interactions */
      }
              `}
              </style>
            </section>
            {serviceData?.section7?.enabled ? (
              <div className="w-full ">
                <ImageWithText
                  {...propsForImages2}
                  title={serviceData?.section7?.head1}
                  backHidden="false"
                  handleClick={() => {
                    buttonClick("prelegate", _id);
                  }}
                  reverseRow={true}
                  btnValue="Questionaire"
                  size={
                    "xl:min-w-[624px] xl:max-w-[624px] max-h-[440px] min-h-[440px]"
                  }
                  // alt="Legal Help on Divorce or Separation"
                  alt={
                    serviceData?.section7?.alt
                      ? serviceData?.section7?.alt
                      : "nill"
                  }
                  heading={serviceData?.section7?.head2}
                  paragraph={
                    <div className=" text-values-align  not-italic font-normal ">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: serviceData?.section7?.description,
                        }}
                        className="!text-[#252525] whitespace-pre-line 3xl:text-[36px] lg:text-sm text-base 3xl:!leading-[130%] lg:!leading-[26px] leading-[34px] tracking-[0.28px] "
                      ></p>
                    </div>
                  }
                />
              </div>
            ) : (
              <div className={`gap-3 flex flex-col items-center  `}>
                <CommonButtonWOLink
                  disabled={questionaireValue}
                  variantVal={"primary"}
                  label={"Questionaire"}
                  handleClick={() => buttonClick("prelegate", _id)}
                />
                {questionaireValue && (
                  <div className={`text-center text-xl font-[500] `}>
                    No questions added yet
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <div className={` max-[500px]:block min-[500px]:hidden`}>
          <GoogleReviews reviews={reviews} />
        </div>

        <div className={`min-[500px]:block hidden ${screens} `}>
          <GoogleReviews reviews={reviews} />
        </div>
        {loginFirst === "Open" && (
          <LoginAuthFirst hideLoginModal={hideLoginModal} />
        )}
      </main>
      <Footer />

      {/* <Layout>
        <style>
          {css}
        </style>
        <div>
          <Helmet>
            <title>{metaTitle}</title>
            <link rel="canonical" href={canonicalLink} />
            <meta name="robots" content="INDEX,FOLLOW" />
            <meta name="description" content={metaDescription}></meta>
            <meta name="keywords" content={metaKeywords}></meta>
          </Helmet>
        </div>
        <section className="page-breadcumb-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="wpo-breadcumb-wrap">
                  <h2>{pageName}</h2>
                  <ul>
                    <li>
                      <Link to="/home">Home</Link>
                    </li>
                    <li>
                      <span>&nbsp; / &nbsp;</span>

                      <Link to="/service/prelegate">Prelegate</Link>
                    </li>
                    <li>
                      <span>&nbsp; / &nbsp;</span>

                      <Markup content={pageName} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="sec-categories">
          <section className="content-page policy-content about-box">
            <div className="container">
              <img src={pageImage} alt="image" />            
              <div className="summaryContainer">
                <div dangerouslySetInnerHTML={{ __html: pageSummary }} />
              </div>
              <a
                onClick={() => buttonClick('prelegate', _id)}
                style={{ color: 'white' }}
                className="theme-btn btn-style-four"
              >
                Continue To Questionnaire
              </a>
            </div>
          </section>
          {loginFirst === 'Open' && (
            <LoginAuthFirst hideLoginModal={hideLoginModal} />
          )}
        </div>
      </Layout> */}
    </>
  );
};
export default SummaryPage;

const LegatecaLegaSection = ({
  toggleExpansion,
  settoggleExpansion,
  id,
  heading,
  valuesFortoggle,
}) => {
  return (
    <>
      <div
        onClick={() => settoggleExpansion(id)}
        className={`flex gap-4 items-center cursor-pointer ${
          id !== 0 ? "mt-3" : ""
        }`}
      >
        <h2 className="text-[#E3B576] 3xl:text-[30px] lg:text-lg not-italic font-medium !leading-[45px] tracking-[0.36px] uppercase">
          0{id + 1}.
        </h2>
        <h2
          onClick={() => settoggleExpansion(id)}
          className="text-[#006766] text-lg not-italic font-semibold !leading-[22px] tracking-[0.36px] uppercase 3xl:text-[40px]"
        >
          {heading}
        </h2>
      </div>

      <hr className="bg-[#CECECE] lg:space-y-4" />
      {id === toggleExpansion && (
        <div className="!text-[#252525] lg:text-sm text-base 3xl:text-[30px] 3xl:!leading-[130%]  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
          {valuesFortoggle}
        </div>
      )}
    </>
  );
};
