import React, { useRef, useState, useEffect } from "react";
import {
  Link,
  NavLink,
  useLocation,
  withRouter,
  useHistory,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Slider1 from "./../../assets/images/slider1.jpeg";
import googleIcon from "./../../assets/images/googleIcon.svg";
import NavigationItem from "./NavigationItem/NavigationItem";
import { Helmet } from "react-helmet";
import AboutImage from "./../../assets/images/home-about-img.webp";
import PersonalizedImage from "./../../assets/images/personalized-service.jpg";
import ReadingDocsImage from "./../../assets/images/reading_docs.jpg";
import legatecafirstlogo from "../../assets/images/legatecaFirstLogo.svg";
import legalAssistanceImage from "../../../src/assets/images/legal-assistance-img.png";
import whoWeAreImg from "../../../src/assets/images/who-we-are-img.png";
import lawAdviceIcons from "../../../src/assets/images/law-Advice-Icon.svg";
import teamCoverImg from "../../../src/assets/images/team-Cover-Img.png";
import whatWeDo from "../../../src/assets/images/what-we-do-img.png";
import LegalPoint from "../../../src/assets/images/legal-Points.svg";
import partnerProgram from "../../../src/assets/images/partner-Program.png";
import Feacture1 from "../../../src/assets/images/BusinessMonday.png";
import Feacture2 from "../../../src/assets/images/59_BDaily 1.png";
import Feacture3 from "../../../src/assets/images/newswire-logo 1.png";
import Feacture4 from "../../../src/assets/images/Nuse.png";
import Feacture5 from "../../../src/assets/images/UK news.png";
import Feature1 from "../../../src/assets/images/BusinessMondayPhone.png";
import Feature2 from "../../../src/assets/images/59_BDaily.png";
import Feature3 from "../../../src/assets/images/newswire.png";
import Feature4 from "../../../src/assets/images/nusephone.png";
import Feature5 from "../../../src/assets/images/UKNEWS.png";
import heroimg from "../../../src/assets/images/hero-background.png";
import img1 from "../../../src/assets/images/img1.png";
import img2 from "../../../src/assets/images/img 2.png";

import img3 from "../../../src/assets/images/img3.png";
import heroImage1 from "../../../src/assets/images/hero-background.png";
import heroImage2 from "../../../src/assets/images/hero-background-2.jpg";

import img4 from "../../../src/assets/images/img4.png";
import arrowLeft from "../../../src/assets/images/arrow-left.svg";
import arrowright from "../../../src/assets/images/arrow-right.svg";
import "./../../Sass/MobileView.scss";
import Header from "../Header";

import { Carousel, Container, Row, Col, Image } from "react-bootstrap";
import HelpCard from "../ReUseAble/HelpCard";
import ImageWithText from "../ReUseAble/ImageWithText";
import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import 'swiper/css/au';
const Homepage = (props) => {
  const personalSection = useRef(null);

  // get path location
  const swiperRef = useRef(null);
  useEffect(() => {
    swiperRef.current = new Swiper(".swiper", {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 1,
      spaceBetween: 20,
      loop: false,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
      },
      autoplay: {
        delay: 3000, // 5 seconds
        disableOnInteraction: false,
      },
    });
  }, []);
  const { user } = useSelector((store) => store.auth);
  const history = useHistory();
  const [openSection, setOpenSection] = useState("legaldocument");
  const [startX, setStartX] = useState(null);
  const [endX, setEndX] = useState(null);
  const [backgroundPosition, setBackgroundPosition] = useState("100% 0%");
  const [currentService, setCurrentService] = useState(
    "Business Legal Services"
  );
  const [currentSlide, setCurrentSlide] = useState(1);
  const [currentView, setCurrentView] = useState("View");
  const [width, setWidth] = useState(1);
  const [hoveredDiv, setHoveredDiv] = useState(null);
  /*Intercom Chat Start*/

  useEffect(() => {
    if (user === null) {
      {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "ovuwc3dn",
        };

        if (!!window.Intercom) {
          window.Intercom("shutdown");
        }

        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.src = "https://widget.intercom.io/widget/ovuwc3dn";
              var x = d.getElementsByTagName("script")[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
              l();
            } else if (w.attachEvent) {
              w.attachEvent("onload", l);
            } else {
              w.addEventListener("load", l, false);
            }
          }
        })();
      }
    } else {
      /*Chat First Name Start*/
      {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "ovuwc3dn",
          name: user.firstName, // First name
          email: user.email, // Email address
          created_at: "<%= current_user.created_at.to_i %>", // Signup date as a Unix timestamp
        };
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.src = "https://widget.intercom.io/widget/ovuwc3dn";
              var x = d.getElementsByTagName("script")[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
              l();
            } else if (w.attachEvent) {
              w.attachEvent("onload", l);
            } else {
              w.addEventListener("load", l, false);
            }
          }
        })();
      }
      /*Chat First Name End*/
    }
  }, []);
  /*Intercom Chat End*/

  try {
    let prevMetaDesc = document.getElementById("baseDescription");
    prevMetaDesc.remove();
    //use the piece of loading state to return other component until you have the data
  } catch (error) {}
  const handleService = (path) => {
    history.push(path);
  };
  const changeImage = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 1 ? 2 : 1));
    setBackgroundPosition((prevPosition) =>
      prevPosition === "100% 0%" ? "0% 0%" : "100% 0%"
    );

    // Change the current service when the image changes
    setCurrentService((prevService) =>
      prevService === "Business Legal Services"
        ? "Personal Legal Services"
        : "Business Legal Services"
    );
    setWidth((prevWidth) => prevWidth + 1);
  };

  // useEffect(() => {
  //   const interval = setInterval(changeImage, 8000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (width >= 100) {
        setWidth(1);
        changeImage();
      } else {
        setWidth((prevWidth) => prevWidth + 1);
      }
    }, 65);

    return () => clearInterval(intervalId);
  }, [width]);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (startX && endX) {
      const deltaX = endX - startX;
      if (deltaX > 50) {
        setCurrentSlide(2);
        setBackgroundPosition("100% 0%");
        setCurrentService("Personal Legal Services");
      } else if (deltaX < -50) {
        setCurrentSlide(1);
        setBackgroundPosition("0% 0%");
        setCurrentService("Business Legal Services");
      }
    }
    setStartX(null);
    setEndX(null);
  };

  const reviews = [
    {
      text: "Excellent service from the team from Legateca, especially Zakia who gave me some invaluable help",
      name: "Chris Raywood",
      date: "7 Months ago",
    },
    {
      text: "Amazing and professional work from Zakia, strongly recommend this company for their services",
      name: "Wendy",
      date: "9 Months ago",
    },
    {
      text: "Very promt, accessible almost anytime, reliable, and more importantly professional with a price that will not break your bank",
      name: "Karim Younas",
      date: "Year ago",
    },
    {
      text: "We have received an excellent service from the team at Legateca. Our business immigration case is being handled by an experienced solicitor, who understood our requirements, provided us with options and has been very supportive throughout. We would highly recommend the service.",
      name: "Huang Kifi",
      date: "Year Ago",
    },
  ];
  const testimonialColors = ["#609DC5", "#F20091", "#1492E6", "#C935B6"];

  // Inside your component or loop where you are rendering these elements

  const [showFullText, setShowFullText] = useState(reviews.map(() => false));

  const toggleReadMore = (index) => {
    const updatedShowFullText = [...showFullText];
    updatedShowFullText[index] = !updatedShowFullText[index];
    setShowFullText(updatedShowFullText);
  };

  const toggleExpansion = (heading) => {
    if (openSection === heading) {
      setOpenSection(null); // Close the currently open section
    } else {
      setOpenSection(heading); // Open the clicked section
    }
  };

  const getStarted = () => {
    history.push("/signup");
  };

  const [showKnowMore, setShowKnowMore] = React.useState(false);

  const onClick = () => setShowKnowMore((prevCheck) => !prevCheck);

  const css = `
    h1, h2, h3, h4, h5, h6 { 
      font-family: "Montserrat", sans-serif;
    }
  `;
  // const [currentService, setCurrentService] = useState(
  //   "Business Legal Services"
  // );
  // const [currentSlide, setCurrentSlide] = useState(1);
  // const [currentView, setCurrentView] = useState("View");

  function handleViewClick() {
    if (currentService === "Business Legal Services") {
      history.push("/lawyer");
    } else if (currentService === "Personal Legal Services") {
      history.push("/service/prelegate");
    }
  }

  const handleArrowClick = () => {
    setCurrentService((prevService) =>
      prevService === "Business Legal Services"
        ? "Personal Legal Services"
        : "Business Legal Services"
    );

    setCurrentSlide((prevSlide) => (prevSlide === 1 ? 2 : 1));

    setCurrentView((prevView) => (prevView === "View" ? "View" : "View"));
  };

  // const backgroundStyle = {
  //   backgroundImage: `url(${currentSlide === 1 ? heroImage1 : heroImage2})`,
  // };

  function ImageLink({ src, alt, targetUrl }) {
    const handleImageClick = () => {
      // Navigate to the specified URL when the image is clicked
      window.location.href = targetUrl;
    };

    return (
      <div className="features-partner img-filter" onClick={handleImageClick}>
        <img src={src} alt={alt} />
      </div>
    );
  }
  let propsForImages = {
    showKnowMore,
    image: whoWeAreImg,
    legatecafirstlogo,
  };
  let propsForImages2 = {
    showKnowMore,
    image: teamCoverImg,
    legatecafirstlogo,
  };
  let classToBoldHeadingInSmallScreens =
    "lg:leading-[48px] leading-[150%] lg:font-medium font-semibold";
  return (
    <React.Fragment>
      <div>
        <Helmet>
          <meta name="robots" content="INDEX,FOLLOW" />
          <meta
            name="title"
            content="Legateca | Professional Legal Advisor & Lawyer Services"
          />
          <title>Legateca | Professional Legal Advisor & Lawyer Services</title>
          <meta
            name="description"
            content="Legateca provides professional legal advice and services for individuals, businesses, organizations, and more. Get the best legal advice and support from us!"
          ></meta>
          <style>{css}</style>
        </Helmet>
      </div>
      <article>
        {/* HeroSection */}

        <section
          className="herosection relative !min-h-[774px] !max-h-[774px]"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={{
            backgroundImage: `url(${
              currentSlide === 1 ? heroImage1 : heroImage2
            })`,
            backgroundPosition: currentSlide === 1 ? "94% 0%" : "",
            transition: "background-position 0.5s",
          }}
        >
          <div className="overlay ">
            <Header />
            <div className="herosection-data ">
              <div className="overlay-material gap-[138px] absolute bottom-0">
                <div className="hero-material-width lg:px-0  md:padding-md padding-sm">
                  <div className="hero-tagline">Looking for legal help</div>
                  <h1 className="hero-text md:max-w-[536px] ">
                    Hire a Lawyer at
                    <br /> Legateca!
                  </h1>
                  <div className="hero-discription md:max-w-[536px] ">
                    Whether it is about solving a family matter or dealing with
                    a big business issue, you always seek timely and feasible
                    solutions to your problems, which can be provided by the
                    best lawyers only.{" "}
                  </div>
                  <div className="cursor-pointer">
                    <a
                      onClick={onClick}
                      style={{ color: "white" }}
                      className="home-theme-btn cursor-pointer mt-[30px] d-flex herobutton items-center  btn-style-four"
                    >
                      <img
                        src={legatecafirstlogo}
                        alt="nill"
                        className="legateca-button-logo"
                      />
                      <div
                        className="hero-button-text cursor-pointer lg:text-[14px] text-xs"
                        onClick={getStarted}
                      >
                        get Started
                      </div>
                    </a>
                  </div>
                </div>
                <div className="slider ">
                  <div
                    style={{
                      height: "100%",
                      background: "#007372",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    onClick={handleViewClick}
                    className="Slide-loader rounded-r-lg cursor-pointer px-6 w-full lg:max-w-[636px]"
                  >
                    <div
                      style={{
                        maxWidth: "637px",
                        width: `${width}%`,
                        height: "100%",
                        background: "#007F7D",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        transition: "width 0.1s ease-in-out", // Smooth transition
                      }}
                    />
                    <div style={{ zIndex: "999" }} className="Services">
                      {currentService}
                    </div>
                    <div className="d-flex align-center">
                      <div
                        style={{ zIndex: "999" }}
                        className="view-div cursor-pointer"
                      >
                        {currentView}
                      </div>
                      <div style={{ zIndex: "999" }} className="big-line"></div>
                    </div>
                  </div>
                  <div className="slider-control self-end lg:flex hidden h-full">
                    <div className="slide-Number">0{currentSlide}/02</div>
                    <div className="Arrow" onClick={handleArrowClick}>
                      <img
                        className="arrow-icons"
                        src={arrowLeft}
                        alt="arrow"
                      />
                      <img
                        className="arrow-icons"
                        src={arrowright}
                        alt="arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={heroimg} alt="" /> */}
        </section>
        {/* At Legateca */}
        <section
          id="personal-services"
          ref={personalSection}
          className="font-montserrat  flex  text-center w-full h-full "
        >
          <div className="flex flex-col w-[100%] lg:mt-[100px] lg:mb-[165px] lg:p-0 padding-md mb-[50px] mt-10">
            <div className="text-[#E3B576] not-italic font-medium !leading-[45px] 3xl:!leading-[130%] tracking-[0.28px] uppercase 3xl:text-[30px] lg:text-sm text-base !m-0">
              at Legateca
            </div>
            {/* <h2 className='text-[#006766] text-[25px] sm:text-[30px] md:text-[38px] lg:text-[42px] font-[70px] md:font-[80px] mb-10 tracking-[0.84px]'> */}
            <h2 className="text-[#006766] 3xl:text-[60px] lg:text-[42px] text-[32px] 3xl:!leading-[130%] not-italic  font-medium leading-[48px] tracking-[0.84px] uppercase 3xl:mb-[80px] lg:mb-[30px] mb-6">
              How can we help you?
            </h2>
            <div className="grid md:grid-cols-2 xl:grid-cols-4 grid-cols-1 lg:gap-5 gap-8 mx-auto lg:px-[100px] ">
              <HelpCard
                setHoveredDiv={setHoveredDiv}
                hoveredDiv={hoveredDiv === 1}
                img={img1}
                alt="lawyers instant"
                navigateLink={"/service/prelegate"}
                value={1}
                heading={"get started"}
                paragraph={
                  <div className="text-white text-xs 3xl:text-[40px]  md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3  ">
                    {/* paragraph={<div className=' mb-3 text-xs md:text-sm font-[500] leading-[19.5px] tracking-[0.28px]  '> */}
                    Use our "Virtual Paralegal" service for free. Instant
                    results!
                  </div>
                }
              />

              <HelpCard
                setHoveredDiv={setHoveredDiv}
                hoveredDiv={hoveredDiv === 2}
                img={img2}
                alt="lawyers instant advice"
                navigateLink={"/service/recommendate"}
                value={2}
                heading={"Instant Advice"}
                paragraph={
                  <div className="text-white text-xs 3xl:text-[40px] md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3  ">
                    {/* paragraph={<div className=' mb-3 text-xs md:text-sm font-[500] leading-[19.5px] tracking-[0.28px]  '> */}
                    Get immediate advice from our Artificial
                    Intelligence-powered Legal Advisor!
                  </div>
                }
              />

              <HelpCard
                setHoveredDiv={setHoveredDiv}
                hoveredDiv={hoveredDiv === 3}
                img={img3}
                navigateLink={"/lawyer"}
                alt="lawyers instant"
                value={3}
                heading={"Find a Lawyer"}
                paragraph={
                  <div className="text-white text-xs 3xl:text-[40px] md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3">
                    Find, compare, and hire services from the top lawyers out
                    there
                  </div>
                }
              />

              <HelpCard
                setHoveredDiv={setHoveredDiv}
                hoveredDiv={hoveredDiv === 4}
                img={img4}
                alt="mediation services UK
                "
                navigateLink={"/mediate"}
                value={4}
                heading={" Mediators & Coaches"}
                paragraph={
                  <div className="text-white text-xs 3xl:text-[40px] md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3  ">
                    Get impartial third-party assistance online in resolving
                    your disputes here
                  </div>
                }
              />
            </div>
          </div>
        </section>

        {/* who we are */}
        <ImageWithText
          {...propsForImages}
          title=" Who are we?"
          to={"/about"}
          alt="Legateca provides personalized services to our customers"
          heading="Empower Your Legal Journey with Confidence and Ease"
          paragraph={
            <div className=" text-values-align  not-italic font-normal ">
              <p className="!text-[#252525] 3xl:text-[36px] lg:text-sm text-base 3xl:!leading-[130%] lg:!leading-[26px] leading-[34px] tracking-[0.28px] ">
                {" "}
                Legal matters can be daunting, expensive, and overwhelming. At
                Legateca, we understand the challenges you face, and that's why
                we're here to make the legal process easy and accessible for
                you. As a simple, budget-friendly, and convenient online
                platform, we provide a seamless experience on your journey from
                distress to consolation. With Legateca, you can say goodbye to
                confusion and hello to clarity.{" "}
              </p>
            </div>
          }
        />
        {/* ends */}
        {/* best legal assistance */}
        <section className="bg-[#006766]/10 py-10  font-montserrat w-[100%] lg:mt[-165px] ">
          <div className="w-[85%] mx-auto">
            <div className="grid grid-flow-row xl:grid-cols-2 gap-5 3xl:max-h-[1000px]">
              <div className=" ">
                <div className="">
                  {/* <div className='text-[#E3B576] text-[14px] font-[500] tracking-[0.25px] leading-[45px] uppercase'> */}
                  <div className="text-[#E3B576] text-values-align lg:text-sm text-base not-italic font-medium 3xl:text-[30px] 3xl:!leading-[130%] !leading-[45px] tracking-[0.28px] uppercase">
                    best legal assistance
                  </div>
                  <div
                    className={`text-[#006766] ${classToBoldHeadingInSmallScreens} 3xl:text-[60px]  text-values-align text-[32px] 3xl:!leading-[130%] lg:text-[42px] not-italic  tracking-[0.84px] uppercase `}
                  >
                    helping people and businesses across the country
                  </div>
                </div>
                {/* <div className='text-[#252525] text-[14px] font-[60px] leading-[26px] tracking-[0.28px]'> */}
                <div className="text-[#252525] text-values-align  not-italic font-normal ">
                  <p className="!text-[#252525] 3xl:text-[36px] lg:text-sm text-base 3xl:!leading-[130%] lg:!leading-[26px] leading-[34px] tracking-[0.28px]">
                    If you are in search of the best legal assistance for your
                    problems, you are at the right place. We have long been
                    helping people and businesses across the country. At
                    Legateca, you can get expert legal consultation for a wide
                    range of matters upon the specialist knowledge of our highly
                    talented legal professionals.
                  </p>{" "}
                  <p className="!text-[#252525] 3xl:text-[36px] lg:text-sm text-base 3xl:!leading-[130%] lg:!leading-[26px] leading-[34px] tracking-[0.28px]">
                    We are a renowned online platform to help our clients
                    finding prompt legal advice from highly experienced and
                    skilled lawyers in the country. At Legateca, we have a
                    network of renowned and proficient solicitors who are
                    holding experience of resolving legal issues of various
                    levels. Whether you require one-time consultation, or you
                    want to hire a solicitor for legal assistance in complicated
                    legal matters, we get all of your needs covered. We provide
                    you with a great level of ease in accessing legal assistance
                    on your demands.
                  </p>
                </div>
                <div className="flex justify-center md:justify-start">
                  <div className="bg-[#E3B576] hover:bg-[#006766] px-[40px] py-[9px] 3xl:py-6 3xl:px-10  flex 3xl:rounded-lg rounded-md  ">
                    <Link
                      // onClick={onClick}

                      className="flex no-underline "
                      // href="https://legateca.com/about"
                      to="/lawyer"
                    >
                      <img
                        src={legatecafirstlogo}
                        alt="nill"
                        className="3xl:h-[66px] 3xl:w-[50px]"
                      />
                      <div className="my-auto ml-3 text-white 3xl:text-[30px]  uppercase tracking-[0.28px] font-[80px] text-[14px]">
                        Know More
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="xl:block hidden w-full">
                <figure className="xl:h-[100%] 3xl:max-h-[1000px] w-full 3xl:max-w-[1200px] 3xl:mx-auto">
                  <img
                    className="h-[100%] w-full"
                    src={legalAssistanceImage}
                    alt="Legateca provides personalized services to our customers"
                  />
                </figure>
                <a
                  href="https://www.youtube.com/watch?v=kxPCFljwJws"
                  className=""
                >
                  <span className=""></span>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* Testimony */}
        <section className="py-20 w-[100%] ">
          <div className="text-center ">
            {/* <h2 className='text-[#E3B576] text-[14px] font-[500] tracking-[0.25px]  uppercase'> */}
            <h2 className="text-[#E3B576] lg:p-0 padding-md text-center lg:text-sm text-base  3xl:text-[30px] 3xl:!leading-[130%] not-italic font-medium !leading-[45px] tracking-[0.28px] uppercase">
              testimonials{" "}
            </h2>
            {/* <h2 className='text-[#006766] text-[42px] font-[80px] leading-[45px] tracking-[0.84px] uppercase  '> */}
            <h2
              className={`text-[#006766] lg:p-0 padding-md text-center 3xl:text-[60px] 3xl:!leading-[130%] text-[32px] lg:text-[42px] not-italic ${classToBoldHeadingInSmallScreens} mb-10 3xl:mb-20  tracking-[0.84px] uppercase `}
            >
              WHAT OUR CLIENTS SAY ABOUT US
            </h2>
            <div className="grid-swiper lg:p-0 padding-md grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-[85%] mx-auto ">
              {/* <div className={"swiper flex  overflow-hidden"}> */}
              {/* <div className={"swiper-wrapper flex"}> */}
              {reviews.map((review, index) => (
                // <div key={index} className="text-left">
                <div key={index} className="text-left">
                  <div className="shadow-md p-6 3xl:p-10 w-full h-full max-h-full ">
                    <div className="flex top-0 ml-auto w-[26px] h-[26px] 3xl:w-20 3xl:h-10 ">
                      {/* <img
                        className="flex ml-auto"
                        src={googleIcon}
                        alt="Free icon"
                      /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-full h-full"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          d="M26 13.3118C26 12.4338 25.9133 11.5275 25.7688 10.6779H13.2565V15.6908H20.4229C20.1339 17.3052 19.1803 18.7213 17.7644 19.6276L22.0411 22.8846C24.5552 20.5905 26 17.2485 26 13.3118Z"
                          fill="#4280EF"
                        />
                        <path
                          d="M13.2564 26C16.8396 26 19.8449 24.8388 22.0411 22.8563L17.7643 19.6276C16.5796 20.4206 15.048 20.8737 13.2564 20.8737C9.7888 20.8737 6.87021 18.5797 5.80102 15.5209L1.40869 18.8346C3.66265 23.2245 8.22836 26 13.2564 26Z"
                          fill="#34A353"
                        />
                        <path
                          d="M5.80105 15.4926C5.25201 13.8782 5.25201 12.1223 5.80105 10.5079L1.40872 7.16593C-0.469575 10.8478 -0.469575 15.181 1.40872 18.8346L5.80105 15.4926Z"
                          fill="#F6B704"
                        />
                        <path
                          d="M13.2564 5.15508C15.1347 5.12676 16.9841 5.83481 18.3423 7.10929L22.1278 3.3708C19.7293 1.16169 16.5507 -0.0278276 13.2564 0.000494299C8.22836 0.000494299 3.66265 2.77604 1.40869 7.16594L5.80102 10.5079C6.87021 7.42083 9.7888 5.15508 13.2564 5.15508Z"
                          fill="#E54335"
                        />
                      </svg>
                    </div>
                    {/* profile */}
                    <div className="flex -mt-5">
                      <div
                        className="bg-[#006766] rounded-full w-16 h-16 3xl:w-28 3xl:h-28 3xl:text-[24px] flex justify-center items-center mt-1 mr-2 text-white font-bold"
                        style={{
                          backgroundColor: testimonialColors[index],
                        }}
                      >
                        {review.name[0].toUpperCase()}
                      </div>
                      <div className="mt-auto mb-auto text-[#959aad] font-montserrat ml-2">
                        <div className="text-[#bbb] font-montserrat text-base font-medium leading-5 tracking-tighter capitalize">
                          {review.name}
                        </div>
                        <div className=" text-[#bbb] font-montserrat text-xs font-medium leading-5 tracking-tighter capitalize">
                          {review.date}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <svg
                        className="w-[96px] h-[16px] 3xl:w-[126px] 3xl:h-[20px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="96"
                        height="16"
                        viewBox="0 0 96 16"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.04851 0.76532C7.15523 0.436005 7.46221 0.213379 7.80782 0.213379C8.1534 0.213379 8.45937 0.436005 8.56609 0.76532L9.90478 4.89017C9.94442 5.01114 10.0217 5.11685 10.1244 5.19205C10.228 5.26727 10.353 5.30792 10.4801 5.30792L14.8174 5.30588C15.163 5.30588 15.4689 5.5285 15.5757 5.85782C15.6824 6.18616 15.5655 6.54599 15.286 6.7493L11.7771 9.29758C11.6734 9.3728 11.5962 9.47852 11.5565 9.59946C11.5169 9.72147 11.5179 9.85257 11.5565 9.97351L12.8983 14.0974C13.005 14.4267 12.8881 14.7865 12.6086 14.9898C12.3291 15.1931 11.9499 15.1931 11.6704 14.9898L8.1636 12.4395C8.0599 12.3643 7.93587 12.3236 7.80782 12.3236C7.67974 12.3236 7.55572 12.3643 7.45205 12.4395L3.94421 14.9898C3.66466 15.1931 3.28655 15.1931 3.00701 14.9898C2.72646 14.7865 2.60958 14.4267 2.71734 14.0974L4.05807 9.97351C4.09771 9.85257 4.09771 9.72147 4.05807 9.59946C4.0194 9.47852 3.94216 9.3728 3.83849 9.29758L0.329642 6.7493C0.0501015 6.54599 -0.0678185 6.18616 0.038932 5.85782C0.146659 5.5285 0.452628 5.30588 0.79824 5.30588L5.13448 5.30792C5.26256 5.30792 5.3876 5.26727 5.49026 5.19205C5.59392 5.11685 5.67116 5.01114 5.70983 4.89017L7.04851 0.76532Z"
                          fill="#F6BB06"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M27.0485 0.76532C27.1552 0.436005 27.4622 0.213379 27.8078 0.213379C28.1534 0.213379 28.4594 0.436005 28.5661 0.76532L29.9048 4.89017C29.9444 5.01114 30.0217 5.11685 30.1244 5.19205C30.228 5.26727 30.353 5.30792 30.4801 5.30792L34.8174 5.30588C35.163 5.30588 35.4689 5.5285 35.5757 5.85782C35.6824 6.18616 35.5655 6.54599 35.286 6.7493L31.7771 9.29758C31.6734 9.3728 31.5962 9.47852 31.5565 9.59946C31.5169 9.72147 31.5179 9.85257 31.5565 9.97351L32.8983 14.0974C33.005 14.4267 32.8881 14.7865 32.6086 14.9898C32.3291 15.1931 31.9499 15.1931 31.6704 14.9898L28.1636 12.4395C28.0599 12.3643 27.9359 12.3236 27.8078 12.3236C27.6797 12.3236 27.5557 12.3643 27.452 12.4395L23.9442 14.9898C23.6647 15.1931 23.2866 15.1931 23.007 14.9898C22.7265 14.7865 22.6096 14.4267 22.7173 14.0974L24.0581 9.97351C24.0977 9.85257 24.0977 9.72147 24.0581 9.59946C24.0194 9.47852 23.9422 9.3728 23.8385 9.29758L20.3296 6.7493C20.0501 6.54599 19.9322 6.18616 20.0389 5.85782C20.1467 5.5285 20.4526 5.30588 20.7982 5.30588L25.1345 5.30792C25.2626 5.30792 25.3876 5.26727 25.4903 5.19205C25.5939 5.11685 25.6712 5.01114 25.7098 4.89017L27.0485 0.76532Z"
                          fill="#F6BB06"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M47.0485 0.76532C47.1552 0.436005 47.4622 0.213379 47.8078 0.213379C48.1534 0.213379 48.4594 0.436005 48.5661 0.76532L49.9048 4.89017C49.9444 5.01114 50.0217 5.11685 50.1244 5.19205C50.228 5.26727 50.353 5.30792 50.4801 5.30792L54.8174 5.30588C55.163 5.30588 55.4689 5.5285 55.5757 5.85782C55.6824 6.18616 55.5655 6.54599 55.286 6.7493L51.7771 9.29758C51.6734 9.3728 51.5962 9.47852 51.5565 9.59946C51.5169 9.72147 51.5179 9.85257 51.5565 9.97351L52.8983 14.0974C53.005 14.4267 52.8881 14.7865 52.6086 14.9898C52.3291 15.1931 51.9499 15.1931 51.6704 14.9898L48.1636 12.4395C48.0599 12.3643 47.9359 12.3236 47.8078 12.3236C47.6797 12.3236 47.5557 12.3643 47.452 12.4395L43.9442 14.9898C43.6647 15.1931 43.2866 15.1931 43.007 14.9898C42.7265 14.7865 42.6096 14.4267 42.7173 14.0974L44.0581 9.97351C44.0977 9.85257 44.0977 9.72147 44.0581 9.59946C44.0194 9.47852 43.9422 9.3728 43.8385 9.29758L40.3296 6.7493C40.0501 6.54599 39.9322 6.18616 40.0389 5.85782C40.1467 5.5285 40.4526 5.30588 40.7982 5.30588L45.1345 5.30792C45.2626 5.30792 45.3876 5.26727 45.4903 5.19205C45.5939 5.11685 45.6712 5.01114 45.7098 4.89017L47.0485 0.76532Z"
                          fill="#F6BB06"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M67.0485 0.76532C67.1552 0.436005 67.4622 0.213379 67.8078 0.213379C68.1534 0.213379 68.4594 0.436005 68.5661 0.76532L69.9048 4.89017C69.9444 5.01114 70.0217 5.11685 70.1244 5.19205C70.228 5.26727 70.353 5.30792 70.4801 5.30792L74.8174 5.30588C75.163 5.30588 75.4689 5.5285 75.5757 5.85782C75.6824 6.18616 75.5655 6.54599 75.286 6.7493L71.7771 9.29758C71.6734 9.3728 71.5962 9.47852 71.5565 9.59946C71.5169 9.72147 71.5179 9.85257 71.5565 9.97351L72.8983 14.0974C73.005 14.4267 72.8881 14.7865 72.6086 14.9898C72.3291 15.1931 71.9499 15.1931 71.6704 14.9898L68.1636 12.4395C68.0599 12.3643 67.9359 12.3236 67.8078 12.3236C67.6797 12.3236 67.5557 12.3643 67.452 12.4395L63.9442 14.9898C63.6647 15.1931 63.2866 15.1931 63.007 14.9898C62.7265 14.7865 62.6096 14.4267 62.7173 14.0974L64.0581 9.97351C64.0977 9.85257 64.0977 9.72147 64.0581 9.59946C64.0194 9.47852 63.9422 9.3728 63.8385 9.29758L60.3296 6.7493C60.0501 6.54599 59.9322 6.18616 60.0389 5.85782C60.1467 5.5285 60.4526 5.30588 60.7982 5.30588L65.1345 5.30792C65.2626 5.30792 65.3876 5.26727 65.4903 5.19205C65.5939 5.11685 65.6712 5.01114 65.7098 4.89017L67.0485 0.76532Z"
                          fill="#F6BB06"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M87.0485 0.76532C87.1552 0.436005 87.4622 0.213379 87.8078 0.213379C88.1534 0.213379 88.4594 0.436005 88.5661 0.76532L89.9048 4.89017C89.9444 5.01114 90.0217 5.11685 90.1244 5.19205C90.228 5.26727 90.353 5.30792 90.4801 5.30792L94.8174 5.30588C95.163 5.30588 95.4689 5.5285 95.5757 5.85782C95.6824 6.18616 95.5655 6.54599 95.286 6.7493L91.7771 9.29758C91.6734 9.3728 91.5962 9.47852 91.5565 9.59946C91.5169 9.72147 91.5179 9.85257 91.5565 9.97351L92.8983 14.0974C93.005 14.4267 92.8881 14.7865 92.6086 14.9898C92.3291 15.1931 91.9499 15.1931 91.6704 14.9898L88.1636 12.4395C88.0599 12.3643 87.9359 12.3236 87.8078 12.3236C87.6797 12.3236 87.5557 12.3643 87.452 12.4395L83.9442 14.9898C83.6647 15.1931 83.2866 15.1931 83.007 14.9898C82.7265 14.7865 82.6096 14.4267 82.7173 14.0974L84.0581 9.97351C84.0977 9.85257 84.0977 9.72147 84.0581 9.59946C84.0194 9.47852 83.9422 9.3728 83.8385 9.29758L80.3296 6.7493C80.0501 6.54599 79.9322 6.18616 80.0389 5.85782C80.1467 5.5285 80.4526 5.30588 80.7982 5.30588L85.1345 5.30792C85.2626 5.30792 85.3876 5.26727 85.4903 5.19205C85.5939 5.11685 85.6712 5.01114 85.7098 4.89017L87.0485 0.76532Z"
                          fill="#F6BB06"
                        />
                      </svg>
                    </div>{" "}
                    {/* <div className='text-[#252525] font-montserrat text-base font-medium leading-6 tracking-tighter mr-1 mt-4'> */}
                    <div className="text-[#252525] text-sm 3xl:text-[30px] 3xl:!leading-[130%]  not-italic font-medium !leading-[26px] tracking-[0.14px] mr-1 mt-4">
                      {showFullText[index]
                        ? review.text
                        : review.text.length > 100
                        ? review.text.slice(0, 100) + "..."
                        : review.text}
                    </div>
                    <div
                      className="text-[#006766] font-montserrat 3xl:text-[24px]   text-[14px] font-[80px] leading-6 tracking-tighter mt-3 cursor-pointer"
                      // on click toggle read more
                      onClick={() =>
                        window.open(
                          "https://maps.app.goo.gl/w9TbTGD6PyYswc3S7",
                          "_blank"
                        )
                      }
                    >
                      Read More
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={"grid-swiper-small swiper flex  overflow-hidden"}>
              <div className={"swiper-wrapper flex"}>
                {reviews.map((review, index) => (
                  // <div key={index} className="text-left">
                  <div key={index} className="swiper-slide text-left">
                    <div className="shadow-md p-6 w-full h-full max-h-full ">
                      <div className="flex top-0 ml-auto w-[26px] h-[26px]  3xl:w-20 3xl:h-10 ">
                        {/* <img
                          className="flex ml-auto"
                          src={googleIcon}
                          alt="Free icon"
                        /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-full h-full"
                          viewBox="0 0 26 26"
                          fill="none"
                        >
                          <path
                            d="M26 13.3118C26 12.4338 25.9133 11.5275 25.7688 10.6779H13.2565V15.6908H20.4229C20.1339 17.3052 19.1803 18.7213 17.7644 19.6276L22.0411 22.8846C24.5552 20.5905 26 17.2485 26 13.3118Z"
                            fill="#4280EF"
                          />
                          <path
                            d="M13.2564 26C16.8396 26 19.8449 24.8388 22.0411 22.8563L17.7643 19.6276C16.5796 20.4206 15.048 20.8737 13.2564 20.8737C9.7888 20.8737 6.87021 18.5797 5.80102 15.5209L1.40869 18.8346C3.66265 23.2245 8.22836 26 13.2564 26Z"
                            fill="#34A353"
                          />
                          <path
                            d="M5.80105 15.4926C5.25201 13.8782 5.25201 12.1223 5.80105 10.5079L1.40872 7.16593C-0.469575 10.8478 -0.469575 15.181 1.40872 18.8346L5.80105 15.4926Z"
                            fill="#F6B704"
                          />
                          <path
                            d="M13.2564 5.15508C15.1347 5.12676 16.9841 5.83481 18.3423 7.10929L22.1278 3.3708C19.7293 1.16169 16.5507 -0.0278276 13.2564 0.000494299C8.22836 0.000494299 3.66265 2.77604 1.40869 7.16594L5.80102 10.5079C6.87021 7.42083 9.7888 5.15508 13.2564 5.15508Z"
                            fill="#E54335"
                          />
                        </svg>
                      </div>
                      {/* profile */}
                      <div className="flex -mt-5">
                        <div
                          className="bg-[#006766] rounded-full w-16 h-16 flex justify-center items-center mt-1 mr-2 text-white font-bold"
                          style={{
                            backgroundColor: testimonialColors[index],
                          }}
                        >
                          {review.name[0].toUpperCase()}
                        </div>
                        <div className="mt-auto mb-auto text-[#959aad] font-montserrat ml-2">
                          <div className="text-[#bbb] font-montserrat text-base font-medium leading-5 tracking-tighter capitalize">
                            {review.name}
                          </div>
                          <div className=" text-[#bbb] font-montserrat text-xs font-medium leading-5 tracking-tighter capitalize">
                            {review.date}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="96"
                          height="16"
                          viewBox="0 0 96 16"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.04851 0.76532C7.15523 0.436005 7.46221 0.213379 7.80782 0.213379C8.1534 0.213379 8.45937 0.436005 8.56609 0.76532L9.90478 4.89017C9.94442 5.01114 10.0217 5.11685 10.1244 5.19205C10.228 5.26727 10.353 5.30792 10.4801 5.30792L14.8174 5.30588C15.163 5.30588 15.4689 5.5285 15.5757 5.85782C15.6824 6.18616 15.5655 6.54599 15.286 6.7493L11.7771 9.29758C11.6734 9.3728 11.5962 9.47852 11.5565 9.59946C11.5169 9.72147 11.5179 9.85257 11.5565 9.97351L12.8983 14.0974C13.005 14.4267 12.8881 14.7865 12.6086 14.9898C12.3291 15.1931 11.9499 15.1931 11.6704 14.9898L8.1636 12.4395C8.0599 12.3643 7.93587 12.3236 7.80782 12.3236C7.67974 12.3236 7.55572 12.3643 7.45205 12.4395L3.94421 14.9898C3.66466 15.1931 3.28655 15.1931 3.00701 14.9898C2.72646 14.7865 2.60958 14.4267 2.71734 14.0974L4.05807 9.97351C4.09771 9.85257 4.09771 9.72147 4.05807 9.59946C4.0194 9.47852 3.94216 9.3728 3.83849 9.29758L0.329642 6.7493C0.0501015 6.54599 -0.0678185 6.18616 0.038932 5.85782C0.146659 5.5285 0.452628 5.30588 0.79824 5.30588L5.13448 5.30792C5.26256 5.30792 5.3876 5.26727 5.49026 5.19205C5.59392 5.11685 5.67116 5.01114 5.70983 4.89017L7.04851 0.76532Z"
                            fill="#F6BB06"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M27.0485 0.76532C27.1552 0.436005 27.4622 0.213379 27.8078 0.213379C28.1534 0.213379 28.4594 0.436005 28.5661 0.76532L29.9048 4.89017C29.9444 5.01114 30.0217 5.11685 30.1244 5.19205C30.228 5.26727 30.353 5.30792 30.4801 5.30792L34.8174 5.30588C35.163 5.30588 35.4689 5.5285 35.5757 5.85782C35.6824 6.18616 35.5655 6.54599 35.286 6.7493L31.7771 9.29758C31.6734 9.3728 31.5962 9.47852 31.5565 9.59946C31.5169 9.72147 31.5179 9.85257 31.5565 9.97351L32.8983 14.0974C33.005 14.4267 32.8881 14.7865 32.6086 14.9898C32.3291 15.1931 31.9499 15.1931 31.6704 14.9898L28.1636 12.4395C28.0599 12.3643 27.9359 12.3236 27.8078 12.3236C27.6797 12.3236 27.5557 12.3643 27.452 12.4395L23.9442 14.9898C23.6647 15.1931 23.2866 15.1931 23.007 14.9898C22.7265 14.7865 22.6096 14.4267 22.7173 14.0974L24.0581 9.97351C24.0977 9.85257 24.0977 9.72147 24.0581 9.59946C24.0194 9.47852 23.9422 9.3728 23.8385 9.29758L20.3296 6.7493C20.0501 6.54599 19.9322 6.18616 20.0389 5.85782C20.1467 5.5285 20.4526 5.30588 20.7982 5.30588L25.1345 5.30792C25.2626 5.30792 25.3876 5.26727 25.4903 5.19205C25.5939 5.11685 25.6712 5.01114 25.7098 4.89017L27.0485 0.76532Z"
                            fill="#F6BB06"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M47.0485 0.76532C47.1552 0.436005 47.4622 0.213379 47.8078 0.213379C48.1534 0.213379 48.4594 0.436005 48.5661 0.76532L49.9048 4.89017C49.9444 5.01114 50.0217 5.11685 50.1244 5.19205C50.228 5.26727 50.353 5.30792 50.4801 5.30792L54.8174 5.30588C55.163 5.30588 55.4689 5.5285 55.5757 5.85782C55.6824 6.18616 55.5655 6.54599 55.286 6.7493L51.7771 9.29758C51.6734 9.3728 51.5962 9.47852 51.5565 9.59946C51.5169 9.72147 51.5179 9.85257 51.5565 9.97351L52.8983 14.0974C53.005 14.4267 52.8881 14.7865 52.6086 14.9898C52.3291 15.1931 51.9499 15.1931 51.6704 14.9898L48.1636 12.4395C48.0599 12.3643 47.9359 12.3236 47.8078 12.3236C47.6797 12.3236 47.5557 12.3643 47.452 12.4395L43.9442 14.9898C43.6647 15.1931 43.2866 15.1931 43.007 14.9898C42.7265 14.7865 42.6096 14.4267 42.7173 14.0974L44.0581 9.97351C44.0977 9.85257 44.0977 9.72147 44.0581 9.59946C44.0194 9.47852 43.9422 9.3728 43.8385 9.29758L40.3296 6.7493C40.0501 6.54599 39.9322 6.18616 40.0389 5.85782C40.1467 5.5285 40.4526 5.30588 40.7982 5.30588L45.1345 5.30792C45.2626 5.30792 45.3876 5.26727 45.4903 5.19205C45.5939 5.11685 45.6712 5.01114 45.7098 4.89017L47.0485 0.76532Z"
                            fill="#F6BB06"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M67.0485 0.76532C67.1552 0.436005 67.4622 0.213379 67.8078 0.213379C68.1534 0.213379 68.4594 0.436005 68.5661 0.76532L69.9048 4.89017C69.9444 5.01114 70.0217 5.11685 70.1244 5.19205C70.228 5.26727 70.353 5.30792 70.4801 5.30792L74.8174 5.30588C75.163 5.30588 75.4689 5.5285 75.5757 5.85782C75.6824 6.18616 75.5655 6.54599 75.286 6.7493L71.7771 9.29758C71.6734 9.3728 71.5962 9.47852 71.5565 9.59946C71.5169 9.72147 71.5179 9.85257 71.5565 9.97351L72.8983 14.0974C73.005 14.4267 72.8881 14.7865 72.6086 14.9898C72.3291 15.1931 71.9499 15.1931 71.6704 14.9898L68.1636 12.4395C68.0599 12.3643 67.9359 12.3236 67.8078 12.3236C67.6797 12.3236 67.5557 12.3643 67.452 12.4395L63.9442 14.9898C63.6647 15.1931 63.2866 15.1931 63.007 14.9898C62.7265 14.7865 62.6096 14.4267 62.7173 14.0974L64.0581 9.97351C64.0977 9.85257 64.0977 9.72147 64.0581 9.59946C64.0194 9.47852 63.9422 9.3728 63.8385 9.29758L60.3296 6.7493C60.0501 6.54599 59.9322 6.18616 60.0389 5.85782C60.1467 5.5285 60.4526 5.30588 60.7982 5.30588L65.1345 5.30792C65.2626 5.30792 65.3876 5.26727 65.4903 5.19205C65.5939 5.11685 65.6712 5.01114 65.7098 4.89017L67.0485 0.76532Z"
                            fill="#F6BB06"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M87.0485 0.76532C87.1552 0.436005 87.4622 0.213379 87.8078 0.213379C88.1534 0.213379 88.4594 0.436005 88.5661 0.76532L89.9048 4.89017C89.9444 5.01114 90.0217 5.11685 90.1244 5.19205C90.228 5.26727 90.353 5.30792 90.4801 5.30792L94.8174 5.30588C95.163 5.30588 95.4689 5.5285 95.5757 5.85782C95.6824 6.18616 95.5655 6.54599 95.286 6.7493L91.7771 9.29758C91.6734 9.3728 91.5962 9.47852 91.5565 9.59946C91.5169 9.72147 91.5179 9.85257 91.5565 9.97351L92.8983 14.0974C93.005 14.4267 92.8881 14.7865 92.6086 14.9898C92.3291 15.1931 91.9499 15.1931 91.6704 14.9898L88.1636 12.4395C88.0599 12.3643 87.9359 12.3236 87.8078 12.3236C87.6797 12.3236 87.5557 12.3643 87.452 12.4395L83.9442 14.9898C83.6647 15.1931 83.2866 15.1931 83.007 14.9898C82.7265 14.7865 82.6096 14.4267 82.7173 14.0974L84.0581 9.97351C84.0977 9.85257 84.0977 9.72147 84.0581 9.59946C84.0194 9.47852 83.9422 9.3728 83.8385 9.29758L80.3296 6.7493C80.0501 6.54599 79.9322 6.18616 80.0389 5.85782C80.1467 5.5285 80.4526 5.30588 80.7982 5.30588L85.1345 5.30792C85.2626 5.30792 85.3876 5.26727 85.4903 5.19205C85.5939 5.11685 85.6712 5.01114 85.7098 4.89017L87.0485 0.76532Z"
                            fill="#F6BB06"
                          />
                        </svg>
                      </div>{" "}
                      {/* <div className='text-[#252525] font-montserrat text-base font-medium leading-6 tracking-tighter mr-1 mt-4'> */}
                      <div className="text-[#252525] text-sm  not-italic font-medium !leading-[26px] tracking-[0.14px] mr-1 mt-4">
                        {showFullText[index]
                          ? review.text
                          : review.text.length > 100
                          ? review.text.slice(0, 100) + "..."
                          : review.text}
                      </div>
                      <div
                        className="text-[#006766] font-montserrat text-[14px] font-[80px] leading-6 tracking-tighter mt-3 cursor-pointer"
                        // on click toggle read more
                        onClick={() =>
                          window.open(
                            "https://maps.app.goo.gl/w9TbTGD6PyYswc3S7",
                            "_blank"
                          )
                        }
                      >
                        Read More
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="swiper-pagination !bottom-[0.01rem]"></div>
            </div>
          </div>
        </section>
        {/* Team of Lawyer */}
        <ImageWithText
          {...propsForImages2}
          to={"/lawyer"}
          alt="employment law advice uk"
          title={"Our team of lawyers"}
          heading={"specialise in the drafting"}
          paragraph={
            <div className="text-[#252525] 3xl:text-[36px] 3xl:!leading-[130%] lg:text-sm text-base lg:!leading-[26px] leading-[34px]  lg-3xl:max-h-[306px]  lg-3xl:min-h-[306px] text-values-align   tracking-[0.28px]">
              {" "}
              From drafting of all types of statements of claim, memos and
              consultation on litigation. Our professionals have worked their
              own way through to assist their clients and offer valuable
              support. They have a strong grasp of the UK legal system and a
              successful track record of professional achievements. People in
              the country know them for their expert knowledge, especially in a
              crucial situation.
              <div className="my-2 3xl:my-0">
                You can simply search for the type of solicitor you need, go
                through various options suggested, read their full profile,
                which includes their area of expertise, experience, cases,
                client rating, and more. The experts at Legateca hold expertise
                in simplifying legal complex problems on Divorce, Nuptials,
                Employment, Business, Disputes, Mediation and Immigration
                matters.
              </div>
            </div>
          }
        />

        {/* our lawyer provides */}
        <section className="lg:mb-[100px] mb-10 w-full mx-auto max-w-[1240px] 3xl:max-w-[1820px] text-center ">
          <div className="w-full xl:px-0 padding-md ">
            {/* <h2 className='text-[#E3B576] text-[14px] font-[80px] tracking-[0.25px] uppercase'> */}
            {/* <h2 className='text-[#E3B576] text-center lg:text-sm text-base  not-italic font-medium !leading-[45px] tracking-[0.28px] uppercase'>
            </h2> */}
            <div className="text-[#E3B576] text-values-align lg:text-sm 3xl:text-[30px] 3xl:!leading-[130%]  text-base not-italic font-medium !leading-[45px] tracking-[0.28px] uppercase">
              our lawyers provide
            </div>
            {/* <h2 className='text-[#006766] text-[35px] lg:text-[42px] font-[80px] leading-[45px] tracking-[0.84px] uppercase my-2 '> */}
            <div className="text-[#006766] text-values-align text-[32px] 3xl:text-[60px] 3xl:!leading-[130%] lg:text-[42px] not-italic lg:font-medium font-semibold lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase">
              {/* <h2 className={`text-[#006766] text-center text-[42px]  not-italic ${classToBoldHeadingInSmallScreens} mb-10 tracking-[0.84px] uppercase `}> */}
              highly satisfactory solutions at each level
            </div>
            {/* <p className='text-[#252525] text-[14px] font-[60px] leading-[26px] tracking-[0.28px]'> */}

            <p className="!text-[#252525] lg:text-sm text-base 3xl:text-[30px] 3xl:!leading-[130%] 3xl:mb-10 text-values-align  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
              Our team of lawyers specialise in the drafting of all types of
              statements of claim, memos and consultation on litigation. Our
              professionals have worked their own way through to assist their
              clients and offer valuable support. They have a strong grasp of
              the UK legal system and a successful track record of professional
              achievements. People in the country know them for their expert
              knowledge, especially in a crucial situation.
            </p>
            <div className="grid grid-flow-row md:grid-cols-2 gap-3">
              <div className="custom-Border 3xl:!px-6 3xl:!py-[72px] justify-center bg-white items-center text-center sm-[1920px]:p-1 m-auto ">
                <div className=" flex">
                  <img
                    className=" mx-auto my-3 3xl:w-[70px] 3xl:h-[60px]"
                    src={lawAdviceIcons}
                    alt="logo"
                  />
                </div>
                {/* <h5 className='text-[#006766] text-[18px] font-[50px] tracking-[0.36px] '> */}
                <h5 className="text-[#006766] 3xl:text-[40px] 3xl:!leading-[130%] text-center lg:text-lg text-base not-italic font-semibold !leading-[45px] tracking-[0.36px] uppercase ">
                  Employment Law Advice in UK
                </h5>
                {/* <p className='mx-1 -mt-5 text-[#252525] text-[14px] font-[40px] leading-[26px] tracking-[0.28px]'> */}
                <p className="text-[#252525] 3xl:text-[30px] 3xl:!leading-[130%] text-center text-sm not-italic font-normal leading-[26px] tracking-[0.28px]">
                  We provide expert advice and guidance to both employers and
                  employees on a range of issues related to employment law
                </p>
              </div>

              <div className="custom-Border-Reverse 3xl:!px-6 3xl:!py-[72px] justify-center bg-white items-center text-center sm-[1920px]:p-1 m-auto">
                <div className=" flex">
                  <img
                    className=" mx-auto my-3 3xl:w-[70px] 3xl:h-[60px]"
                    src={lawAdviceIcons}
                    alt="logo"
                  />
                </div>
                <h5 className="text-[#006766] 3xl:text-[40px] 3xl:!leading-[130%] text-center lg:text-lg text-base not-italic font-semibold !leading-[45px] tracking-[0.36px] uppercase ">
                  {/* <h5 className="text-[#006766] text-center lg:text-lg text-base not-italic font-semibold !leading-[45px] tracking-[0.36px] uppercase "> */}
                  Small Business Legal advice in UK
                </h5>
                <p className="text-[#252525] 3xl:text-[30px] 3xl:!leading-[130%] text-center text-sm not-italic font-normal leading-[26px] tracking-[0.28px]">
                  {/* <p className="text-[#252525] text-center text-sm not-italic font-normal leading-[26px] tracking-[0.28px]"> */}
                  Our commercial lawyer UK will be your savior while protecting
                  your business legal needs and leading you in the right
                  direction
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* legal assistance */}
        <section className=" mb-7 font-montserrat w-[100%] font-[20px] 3xl:pt-20 3xl:pb-5 py-10 bg-[#f0f6f6]">
          <div className="w-[85%] mx-auto">
            <div className="grid grid-flow-row lg:grid-cols-2 ">
              <div className="">
                <div className="">
                  <div className="text-[#E3B576] 3xl:text-[30px] 3xl:!leading-[130%]  text-values-align lg:text-[14px] text-base font-medium leading-[45px] tracking-[0.25px] uppercase">
                    At Legateca, we are making legal assistance
                  </div>
                  <div
                    className={`text-[#006766] 3xl:text-[60px] 3xl:!leading-[130%]  text-values-align text-[32px] lg:text-[42px]  ${classToBoldHeadingInSmallScreens} tracking-[0.84px] uppercase `}
                  >
                    more accessible, efficient, and affordable
                  </div>
                </div>
                <div className="mr-3">
                  <p className="!text-[#252525] 3xl:text-[30px] 3xl:!leading-[130%]  lg:text-sm text-base   not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
                    {/* <p className='!text-[#252525] text-values-align lg:text-[14px] text-base font-[60px] leading-[26px] tracking-[0.28px]'> */}
                    At Legateca, we make sure that we make legal assistance more
                    accessible, efficient, and affordable to each of clients.
                    Our technology driven assistance seems to be highly
                    appreciable to those who compare time with money. Our
                    lawyers help our clients in the following manner:
                  </p>{" "}
                  <div
                    onClick={() => toggleExpansion("legaldocument")}
                    className="flex "
                  >
                    <img className="flex my-4" src={LegalPoint} alt="line" />
                    <h4
                      onClick={() => toggleExpansion("legaldocument")}
                      className="ml-8 my-auto text-uppercase 3xl:text-[40px]   text-[#006766] font-semibold text-[18px]"
                    >
                      Legal Document Preparation
                    </h4>
                  </div>
                  {openSection === "legaldocument" && (
                    <p className="!text-[#252525] lg:text-sm text-base 3xl:text-[30px] text-values-align 3xl:!leading-[130%]  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
                      Our Commercial Contracts Lawyers, divorce lawyers, family
                      lawyers, and other professionals offer templates and tools
                      to help users create legal documents such as contracts,
                      wills, leases, incorporation documents, and more. These
                      platforms guide users through the process of customizing
                      the documents to meet their specific needs.
                    </p>
                  )}
                  <div
                    onClick={() => toggleExpansion("formallegal")}
                    className="flex 3xl:mt-6"
                  >
                    <img className="flex my-4" src={LegalPoint} alt="line" />
                    <h4
                      onClick={() => toggleExpansion("formallegal")}
                      className="ml-8 my-auto text-uppercase  text-[#006766]  3xl:text-[40px]  font-semibold text-[18px]"
                    >
                      Formal Legal Representation{" "}
                    </h4>
                  </div>
                  {openSection === "formallegal" && (
                    <p className="!text-[#252525] lg:text-sm text-base 3xl:text-[30px] text-values-align 3xl:!leading-[130%]  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
                      In some cases, our solicitors can represent them in legal
                      proceedings, such as court cases or administrative
                      hearings. They allow users to find relevant laws,
                      regulations, and legal precedents. We provide online
                      dispute resolution for certain types of legal issues,
                      offering an alternative to traditional litigation.
                    </p>
                  )}
                  <div
                    onClick={() => toggleExpansion("business")}
                    className="flex 3xl:mt-6"
                  >
                    <img className="flex my-4" src={LegalPoint} alt="line" />
                    <h4
                      onClick={() => toggleExpansion("business")}
                      className="ml-8 my-auto text-uppercase 3xl:text-[40px]   text-[#006766] font-semibold text-[18px]"
                    >
                      Business Formation and Compliance
                    </h4>
                  </div>
                  {openSection === "business" && (
                    <p className="!text-[#252525] lg:text-sm text-base 3xl:text-[30px] text-values-align 3xl:!leading-[130%]  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
                      We help individuals and businesses with the process of
                      forming legal entities like LLCs, corporations, or
                      partnerships. They may also provide guidance on compliance
                      requirements. We also provide educational materials,
                      articles, and resources to help users understand legal
                      concepts and navigate their legal needs better.
                    </p>
                  )}
                  <div
                    onClick={() => toggleExpansion("legaladvice")}
                    className="flex 3xl:mt-6"
                  >
                    <img className="flex my-4" src={LegalPoint} alt="line" />
                    <h4
                      onClick={() => toggleExpansion("legaladvice")}
                      className="ml-8 my-auto text-uppercase  3xl:text-[40px]  text-[#006766] font-semibold text-[18px]"
                    >
                      Legal Advice and Consultation
                    </h4>
                  </div>
                  {openSection === "legaladvice" && (
                    <p className="!text-[#252525] lg:text-sm text-base 3xl:text-[30px] text-values-align 3xl:!leading-[130%]  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
                      Legateca is among the best platforms in the UK that
                      provide access to licensed attorneys who can offer legal
                      advice and consultation to users. This could be through
                      phone calls, video conferencing, or chat. Many users who
                      want initial consultation through corporate commercial
                      lawyers connect with us online.
                    </p>
                  )}
                  <div
                    onClick={() => toggleExpansion("cost")}
                    className="flex 3xl:mt-6"
                  >
                    <img className="flex my-4 " src={LegalPoint} alt="line" />
                    <h4
                      onClick={() => toggleExpansion("cost")}
                      className="ml-8 my-auto text-uppercase 3xl:text-[40px]  text-[#006766] font-semibold text-[18px]"
                    >
                      Cost-Effectiveness
                    </h4>
                  </div>
                  {openSection === "cost" && (
                    <p className="!text-[#252525] lg:text-sm text-base 3xl:text-[30px] text-values-align 3xl:!leading-[130%]  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
                      At Legateca, we believe in making legal assistance more
                      affordable for many individuals and small businesses.
                      Given the sensitive nature of legal matters, we also
                      prioritize user data privacy and security to ensure
                      confidentiality and compliance with data protection
                      regulations.
                    </p>
                  )}
                </div>
                <div className="flex my-10 md:mt-20 justify-center md:justify-normal">
                  <div className="bg-[#E3B576] hover:bg-[#006766] px-[40px] py-[9px] 3xl:py-6 3xl:px-10 flex rounded-md 3xl:rounded-lg ">
                    <Link
                      // onClick={onClick}

                      className="flex no-underline "
                      // href="https://legateca.com/about"
                      to="/mediate"
                    >
                      <img
                        src={legatecafirstlogo}
                        alt="nill"
                        className="3xl:h-[66px] 3xl:w-[50px]"
                      />
                      <div className="my-auto ml-3 text-white  uppercase tracking-[0.28px] 3xl:text-[30px] font-[80px] text-[14px]">
                        Know More
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="md:block hidden">
                <figure className="xl:h-[100%] 3xl:max-h-[1000px] w-full 3xl:max-w-[1200px] 3xl:mx-auto">
                  <img
                    className="h-[100%] w-full "
                    src={"/home-updated.png"}
                    alt="Legateca provides personalized services to our customers"
                  />
                </figure>

                <a
                  href="https://www.youtube.com/watch?v=kxPCFljwJws"
                  className="lightbox-image overlay-box"
                >
                  <span className=""></span>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* Best Choice */}
        <section className="w-full max-w-[1240px] 3xl:max-w-[1820px] mx-auto text-center my-20 3xl:my-[120px] ">
          <div className="xl:p-0 padding-md">
            <div className="text-[#E3B576] text-values-align 3xl:text-[30px] 3xl:!leading-[130%]  lg:text-sm text-base  not-italic font-medium !leading-[45px] tracking-[0.28px] uppercase">
              The Best Choice Is
            </div>
            {/* <h2 className='text-[#006766] text-[42px] font-[80px] leading-[45px] tracking-[0.84px] uppercase '> */}
            <h2
              className={`text-[#006766] text-values-align 3xl:text-[60px] 3xl:!leading-[130%]   text-[32px] lg:text-[42px] not-italic ${classToBoldHeadingInSmallScreens} mb-10 tracking-[0.84px] uppercase  `}
            >
              A professional like Legateca
            </h2>
            <div className="">
              {/* <p className='text-[#252525] text-[14px] font-[60px] leading-[26px] tracking-[0.28px]'> */}
              <p className="!text-[#252525] 3xl:text-[30px] 3xl:!leading-[130%]  lg:text-sm text-base text-values-align  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
                {/* <p className='!text-[#252525] text-center lg:text-sm text-base not-italic font-normal !leading-[26px] tracking-[0.28px]'> */}
                There is no denying that people have to go through situations
                that need legal assistance. These problems need to be handled or
                taken care of by legal professionals to avoid certain fallouts.
                A professional like Legateca can come up as the best choice for
                this job done quite easily.
              </p>{" "}
              {/* <p className='!text-[#252525] text-center text-sm not-italic font-normal !leading-[26px] tracking-[0.28px]'> */}
              <p className="!text-[#252525] 3xl:text-[30px] 3xl:!leading-[130%]  lg:text-sm text-base text-values-align  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]">
                We have been delivering exceptional performance by allowing our
                lawyers and clients to come together to resolve any legal
                problem. We feel proud sharing that our clients admire our team
                of solicitors for their loyalty, prompt, and convenient legal
                assistance. If you are searching for a legal professional to
                take about u care of your legal issues, feel free to connect
                with the experience holding online platforms. We feel passionate
                about assessing the problems of clients and offer them the
                solution they have been looking for. Contact us for your
                concerns- whether you need divorce mediation services or
                employment law solicitors!
              </p>
            </div>
            <div className="flex ">
              <div className="bg-[#006766] hover:bg-[#E3B576] px-[40px] py-[9px] 3xl:py-6 3xl:px-10 mx-auto 3xl:rounded-lg flex rounded-md ">
                <Link
                  // onClick={onClick}

                  className="flex no-underline "
                  // href="https://legateca.com/about"
                  to="/contact-us"
                >
                  <img
                    src={legatecafirstlogo}
                    alt="nill"
                    className="3xl:h-[66px] 3xl:w-[50px]"
                  />
                  <div className="my-auto ml-3 text-white  uppercase tracking-[0.28px]  3xl:text-[30px]  font-[80px] text-[14px]">
                    contact us
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* Partner Program */}
        <section className="w-[100%] font-montserrat ">
          <div className="w-[90%] mx-auto">
            <img src={partnerProgram} alt="nill" />
          </div>
          <div className="bg-[#f0f6f6] -mt-32 px-2 md:-mt-60 h-96 3xl:min-h-[500px] 3xl:max-h-[500px] flex justify-center items-end">
            <div className="flex flex-col items-center lg:flex-row md:w-5/6 mb-4 3xl:mb-0 justify-between padding-md p-0">
              {/* <h2 className='text-[#006765] uppercase'> */}
              <h2 className="text-[#006766] lg:text-[32px] 3xl:text-[60px] 3xl:!leading-[130%] lg:max-w-[630px] 3xl:max-w-[1300px] text-values-align text-[26px]  not-italic font-medium !leading-[37.5px] tracking-[0.64px] uppercase">
                Ordinary people need simple help with their legal troubles
              </h2>
              <div className="flex right-0 my-auto  ">
                <div className="bg-[#E3B576] hover:bg-[#006766] px-7 py-[9px] 3xl:py-6 3xl:px-10 3xl:rounded-lg mx-auto flex rounded-md ">
                  <Link
                    // onClick={onClick}

                    className="flex no-underline "
                    // href="https://legateca.com/about"
                    to="/partner"
                  >
                    <img
                      src={legatecafirstlogo}
                      alt="nill"
                      className="3xl:h-[66px] 3xl:w-[50px]"
                    />
                    <div className="my-auto ml-3 text-white  uppercase tracking-[0.28px] font-[80px] 3xl:text-[30px] text-[14px]">
                      Partnership Program
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="w-[100%] font-montserrat my-28 3xl:my-[120px] text-center">
          <div className="text-[#006766] text-[30px] 3xl:text-[60px] 3xl:!leading-[130%] sm:text-[35px] md:text-[42px] font-[80px] leading-[45px] tracking-[0.84px] uppercase mb-10">
            AS FEATURED IN
          </div>

          <div className="grid lg:grid-cols-5 grid-cols-2 gap-16 w-[90%] justify-between items-center min-h-40 m-auto font-montserrat">
            {/* <div className="featuredLogos"> */}
            <a
              target="_blank"
              // onClick={onClick}
              className="my-auto img-Filter"
              // href="https://legateca.com/about"
              href="https://businessmondays.co.uk/20-of-small-businesses-encounter-cash-flow-problems-due-to-late-payments/"
            >
              <img src={Feacture1} alt="logo - BUSINESS MONDAYS" className="" />
            </a>
            <a
              target="_blank"
              // onClick={onClick}

              className="my-auto img-Filter"
              // href="https://legateca.com/about"
              href="https://bdaily.co.uk/articles/2023/06/22/legateca-serves-up-just-the-right-legal-advice-for-bistro-owner"
            >
              <img
                src={Feacture2}
                alt="Logo - BDAILY
"
                className=""
              />
            </a>
            <a
              target="_blank"
              // onClick={onClick}

              className="my-auto img-Filter"
              // href="https://legateca.com/about"
              href="https://www.enewswire.co.uk/2023/07/07/legateca-shines-spotlight-on-alarming-late-payment-issue-plaguing-uk-small-businesses/"
            >
              <img
                src={Feacture3}
                alt="Logo - NEWS WIRE
"
                className=""
              />
            </a>
            <a
              target="_blank"
              // onClick={onClick}

              className="my-auto img-Filter"
              // href="https://legateca.com/about"
              href="https://www.nuse.online/post/late-payments-causing-small-business-cash-flow-issues"
            >
              <img
                src={Feacture4}
                alt="Logo - Nuse
"
                className=""
              />
            </a>
            <a
              target="_blank"
              // onClick={onClick}

              className=" img-Filter  hidden lg:block"
              // href="https://legateca.com/about"
              href="https://www.uknewsgroup.co.uk/legateca-serves-up-just-the-right-legal-advice-for-bistro-owner/"
            >
              <img
                src={Feacture5}
                alt="Logo - UK News Group
"
                className=""
              />
            </a>
            {/* </div> */}
          </div>
          <div className=" lg:hidden flex justify-center items-center">
            <a
              target="_blank"
              // onClick={onClick}

              className=" img-Filter mx-auto min-w-[230px] md:max-w-[300px] max-w-[240px] mt-16"
              // href="https://legateca.com/about"
              href="https://www.uknewsgroup.co.uk/legateca-serves-up-just-the-right-legal-advice-for-bistro-owner/"
            >
              <img
                src={Feacture5}
                alt="Logo - UK News Group
"
              />
            </a>
          </div>
        </section>
        {/* end */}
      </article>
    </React.Fragment>
  );
};

export const Results = () => (
  <div id="results" className="">
    <br />
    <div className="text-[#e3b576] mb-2 font-medium uppercase">
      <h2>Getting Legal Solutions Made Easy</h2>
    </div>
    <p>
      Law is complicated, and conflicts can be incredibly stressful. Navigating
      through it all on your own can feel like an uphill battle. But fret not,
      because our team of experts is here to assist you every step of the way.
      Whether you need small business legal advice in the UK or personal legal
      solutions, we've got you covered. Simply share your grievances with us,
      and we'll take care of the rest.
      <br />
      Here's why you should choose us:
      <br />
    </p>

    <p>
      <b>1. Ability To Select Your Dispute:</b> We understand that every legal
      matter is unique. That's why we have developed a personalised platform
      that caters to all your contentions. Whether you're looking to end a
      relationship, need guidance with nuptial agreements, facing an
      employment-related dispute, or have a legal business issue, we've got the
      right resources and expertise for you. Select your case, answer a few
      questions about your situation, and receive a free review. Our network of
      solicitors, paralegals, mediators, and McKenzie friends ensures you have a
      wide choice of legal advisors to contact.{" "}
    </p>
    <p>
      <b>2. Available AI Advisor:</b> Time is valuable, and we believe in
      providing instant advice that saves you both time and cost. With our
      AI-assisted Advisor, powered by the latest legal data and insights, you'll
      have access to accurate and up-to-date information online. Our AI advisor
      is currently available for divorce or separation related cases, delivering
      results with over 80% accuracy. We're continuously expanding our AI
      capabilities to cover other areas of law as well. Once you've consulted
      the AI advisor, you can review profiles of vetted lawyers or mediators,
      choose your preferred professional, and proceed with confidence.
    </p>
    <p>
      <b>3. Extensive choice of Legal Experts:</b> Selecting the right legal
      professional who can represent your case effectively is crucial. At
      Legateca, we provide you with a platform that offers a wide selection of
      verified lawyers and mediators. You can compare their profiles based on
      fees, expertise, experiences, and previous cases, allowing you to make an
      informed decision. Once you're satisfied, you can directly book a remote
      video consultation with your chosen legal expert. For added convenience,
      you can also share your documents in advance, ensuring your initial
      meeting is productive and efficient.
    </p>
    <p>
      <b>4. Payments with peace of mind:</b> When it comes to financial
      transactions, we prioritise your security. Our platform adheres to the
      highest industry standards, compliant with the Payment Card Industry Data
      Security Standards (PCI DSS). This means you can confidently use your
      cards to pay legal fees and book appointments with our experts. We
      understand the importance of trust, especially when it comes to paying for
      sessions before speaking with a professional. If you're not satisfied
      after a session, simply raise a dispute, and we'll investigate and help
      facilitate a resolution. If the outcome with the legal professional is
      unsatisfactory, we'll refund your payment. Please refer to our terms for
      more details.
    </p>
    <p>
      <b>5. Track Your Appointments:</b> With Legateca, you're always in
      control. We provide you with a dedicated portal where you can access
      stored documents, review your payments history, and manage your sessions
      booked with legal experts. Our portal also allows you to verify your
      identity, saving you time during meetings and allowing you to focus on the
      core issues. Should you need to book a legal advisor again, you can easily
      do so from our platform and continue progressing with your case
      seamlessly.
    </p>
    <br />
    <br />
    <div className="text-[#e3b576] mb-2 font-medium uppercase">
      <h2>Let’s Get To Resolutions</h2>
    </div>

    <p>
      If you're in need of legal assistance, we invite you to explore our
      features or reach out to us with any inquiries at info@legateca.com or +44
      1635 745074. Alternatively, you can fill out the form to provide us with
      some insight, and we'll promptly get back to you. Click Here:{" "}
      <a href="https://legateca.com/contact-us">Contact Us</a>
    </p>
  </div>
);

export default Homepage;
