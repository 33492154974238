import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, useParams, useHistory, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import services from "../../../server/services";
import Separation from "../../../assets/images/law-sepration.jpeg";
import EmployementLaw from "../../../assets/images/employement-law.jpeg";
import HousingREstate from "../../../assets/images/housing-real-estate.jpeg";
import ConsumerLaw from "../../../assets/images/consumer-law.jpeg";
import ImigrationLaw from "../../../assets/images/imigration-law.jpeg";
import Nupital from "../../../assets/images/nupital1.jpeg";
import LoginAuthFirst from "../../Modals/LoginAuth";
import { Helmet } from "react-helmet";
import { HeroSection } from "../../../Pages/About";
import Footer from "../../Footer";
import { useRef } from "react";
import { CommonButtonWOLink } from "../../LawyemModule/Lawyem/Lawyem";

let id_code;
let all = [];
const cateId = [];

const Prelegate = (props) => {
  let { serviceName } = useParams();
  const [loginFirst, setloginFirst] = useState(false);
  const [limitValue, setlimitValue] = useState(8);
  const [userData, setUserData] = useState(null);
  const [tag, setTag] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    try {
      const getMail = JSON.parse(sessionStorage.getItem("state"));
      const token = sessionStorage.getItem("idToken");
      const user_id = getMail.auth.user?._id;

      fetch(
        // `${process.env.REACT_APP_API_URL}/transactions?userId=${user_id}&tag=${!tag  ? '' : tag}&isUsed=false&paymentFor=recommendate`,
        `${process.env.REACT_APP_API_URL}/transactions?userId=${user_id}&isUsed=false&paymentFor=recommendate`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("idToken"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          const res_metadata_json = JSON.parse(JSON.stringify(json));
          const res_metadata_id_json = res_metadata_json.data;

          var allCateId = res_metadata_id_json.map(
            (obj) => obj.metaDataObject.id_category
          );

          //console.log(allCateId);
          //console.log(typeof(allCateId));
          const array = cateId.concat(allCateId);

          const filtered = array.filter(function (x) {
            return x !== undefined;
          });

          all = filtered;
          // console.log("filtered", filtered);
          //console.log(JSON.stringify(res_metadata_id_json))
        })
        .catch(function (ex) {});
    } catch (error) {
      console.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  });

  useEffect(() => {
    if (serviceName === "recommendate") {
      getRecommendateTrasaction();
    }
    getCategories();
  }, [tag, serviceName, limitValue]);

  useEffect(() => {
    setUserData(props.user);
  }, [props.user]);

  const getRecommendateTrasaction = async () => {
    if (localStorage.getItem("idToken")) {
      await services["transactions"].find({
        query: {
          userId: props?.user?._id,
          paymentFor: "recommendate",
          isUsed: false,
        },
      });
    }
  };

  useEffect(() => {
    services["general-data"].find({});
  }, []);

  const redirectToPaymentPage = async () => {
    const jsonObject = await props.categories.queryResult;

    const res = JSON.parse(JSON.stringify(jsonObject));
    const h = res.data;
    var result = h.filter((obj) => obj._id == id_code);

    const amount = await props.generalData.queryResult;
    // if (!result[0]?.promotionalprice || !result[0]?.promotionaldate) {
    //   alert("No promotional price found")

    //   return
    // }
    const amount_per_service = await result[0].price;
    const promotional_amount_per_service = await result[0]?.promotionalprice;
    const promotional_date_per_service = await result[0]?.promotionaldate;

    //console.log(JSON.stringify(amount))
    //console.log(JSON.stringify(promotional_amount_per_service))
    if (amount.total > 0) {
      let promotionPrice, vatAmount;

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const currentDate = new Date().getDate();
      //const [date, month, year] =
      //amount.data[0].recommendatePromotionDate.split("-");
      const [date, month, year] = promotional_date_per_service?.split("-");

      if (year > currentYear) {
        //promotionPrice = amount.data[0].recommendatePromotionPrice;
        promotionPrice = promotional_amount_per_service;
      } else if (year == currentYear) {
        if (month > currentMonth) {
          promotionPrice = promotional_amount_per_service;
        } else if (month == currentMonth) {
          if (date >= currentDate) {
            promotionPrice = promotional_amount_per_service;
          } else {
            promotionPrice = false;
          }
        } else {
          promotionPrice = false;
        }
      } else {
        promotionPrice = false;
      }

      if (promotionPrice != false) {
        vatAmount = promotional_amount_per_service * 0.2;
      } else {
        //vatAmount=amount.data[0].recommendateMrp*0.2
        vatAmount = amount_per_service * 0.2;
      }

      props.history.push({
        pathname: "/payment",
        state: {
          recommendatePromotionPrice: promotionPrice,
          //amount: amount.data[0].recommendateMrp*100,
          amount: amount_per_service * 100,
          paymentFor: "recommendate",
          successRedirectUrl: "/service/recommendate",
          failureRedirectUrl: "/service/recommendate",
          lawyerId: "",
          metaData: {
            vat: 0.2,
            id_category: id_code,
            amount: amount_per_service,
            discountAmount: promotionPrice,
            vatAmount: vatAmount,
          },
          notification: true,
        },
      });
    }
  };

  const getCategories = async () => {
    try {
      await services["categories"].find({
        query: {
          service: serviceName,
          ...(!!tag ? { tag: tag.toLowerCase() } : {}),
          // $sort: {
          //   createdAt: 1,
          // },
          $skip: 0,
          $limit: limitValue,
        },
      });
    } catch (error) {
      console.log({ error });
    }
  };
  const cardClick = (serviceName, id, prelegateServiceName) => {
    if (userData && userData.email) {
      if (serviceName === "recommendate") {
        if (all.includes(id) === false) {
          id_code = id;
          redirectToPaymentPage();
        } else {
          window.location.replace(`/service/${serviceName}/${id}`);
        }
      } else {
        if (serviceName === "recommendate") {
          window.location.replace(`/service/${serviceName}/${id}`);
        } else {
          history.push(`/prelegate/${prelegateServiceName}`);
        }
      }
    } else {
      if (serviceName === "recommendate") {
        // toast.error("Session expired. Please login again.", {
        //   hideProgressBar: true,
        //   autoClose: 2000,
        // });
        // props.history.push({
        //   pathname: "/login",
        //   state: window.location.pathname,
        // });
        setloginFirst(true);
        return;
      }
      history.push(`/prelegate/${prelegateServiceName}`);
    }
  };

  const hideLoginModal = () => {
    setloginFirst("Closed");
  };
  const tabArray = [
    {
      id: 0,
      title: "All",
    },
    {
      id: 1,
      title: "Family",
    },
    {
      id: 2,
      title: "Immigration",
    },
    {
      id: 3,
      title: "Employment",
    },
    {
      id: 4,
      title: "housing",
    },
    {
      id: 5,
      title: "Business",
    },
    {
      id: 6,
      title: "Criminal",
    },
  ];
  // const tabArray1 = [
  //   {
  //     id: 0,
  //     title: "All"
  //   },
  //   {
  //     id: 1,
  //     title: "Divorce"
  //   },
  //   {
  //     id: 2,
  //     title: "business"
  //   },
  //   {
  //     id: 3,
  //     title: "Employment"
  //   },

  // ];
  const [activeTab, setActiveTab] = useState(0);
  const [hovereDiv, setHoveredDiv] = useState(null);
  let images = [
    "/divorce.png",
    "/nupitals.png",
    "/employment.png",
    "/business.png",
    "/business2.png",
    "/mediation.png",
    "/immigration.png",
    "/divorce.png",
  ];
  function getServiceName() {
    switch (serviceName) {
      case "prelegate":
        return "Get started";
      case "recommendate":
        return "Instant Advice";
      default:
        return;
    }
  }
  function renderTabsHeaders() {
    switch (serviceName) {
      case "prelegate":
        return (
          <div className="w-full flex flex-col 3xl:gap-7">
            <div className="text-[#E3B576] text-center 3xl:text-[30px] text-sm not-italic font-medium leading-[45px] tracking-[0.28px] uppercase">
              what are you looking for
            </div>
            <div className="text-[#006766] text-center text-[42px] 3xl:text-[60px] not-italic font-medium leading-[48px] tracking-[0.84px] uppercase">
              GET STARTED
            </div>
          </div>
        );
      case "recommendate":
        return (
          <div className="w-full flex flex-col 3xl:gap-7 max-w-[1110px] 3xl:max-w-[1510px] mx-auto">
            <div className="text-[#E3B576] text-center 3xl:text-[30px] text-sm not-italic font-medium leading-[45px] tracking-[0.28px] uppercase">
              the best so far
            </div>
            <div className="text-[#006766] text-center text-[42px] 3xl:text-[60px] not-italic font-medium leading-[48px] tracking-[0.84px] uppercase">
              our AI advisor
            </div>
            <div className="text-[#252525] 3xl:text-[30px] 3xl:!leading-[130%] mt-[15px] text-center text-sm not-italic font-normal leading-[26px] tracking-[0.28px]">
              We constantly train and enhance our AI advisor with a plethora of
              cases such as yours, to cater to your legal needs instantly with
              over 80% accuracy - the best so far in the field!
            </div>
          </div>
        );
      // case:
      default:
        return;
    }
  }
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={
            serviceName === "prelegate"
              ? "https://legateca.com/service/prelegate"
              : "https://legateca.com/service/recommendate"
          }
        />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta
          name="description"
          content="Legateca provides professional legal advice and services for individuals, businesses, organizations, and more. Get the best legal advice and support from us!"
        ></meta>
      </Helmet>
      <HeroSection
        heading={getServiceName()}
        bgImg={
          serviceName === "prelegate"
            ? "/getstarted.png"
            : "/recommendateHero.png"
        }
      />
      <main className="xl:py-[100px] py-[70px] w-full mx-auto lg:px-0 md:px-8 px-6 xl:max-w-[1240px] 3xl:max-w-[2400px]">
        <div className="w-full flex flex-col gap-[30px] items-center">
          {renderTabsHeaders()}
          <ToggleTabs
            tabArray={tabArray}
            serviceName={serviceName}
            activeTab={activeTab}
            setTag={setTag}
            setActiveTab={setActiveTab}
          >
            <div className="grid relative md:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 justify-center  lg:gap-5 gap-8 ">
              {props.categories.isLoading ? (
                // <div>Loading...</div>
                <div class="loader-loader absolute -top-2"></div>
              ) : // <div class="loader-loader -top-2"></div>

              !props.categories.isError &&
                !props.categories.isLoading &&
                props.categories.queryResult.total > 0 ? (
                props.categories.queryResult.data.map((category, index) => {
                  return (
                    <div
                      onClick={() => {
                        cardClick(serviceName, category?._id, category.name);
                      }}
                      key={index}
                      className=" mx-auto  h-full"
                    >
                      <div
                        onMouseEnter={() => setHoveredDiv(index)}
                        onMouseLeave={() => setHoveredDiv(null)}
                        className={` relative w-full h-full  mx-auto max-w-[296px] 3xl:min-w-[500px] 3xl:max-w-[500px] xl:max-h-[298px] xl:min-h-[298px] 3xl:min-h-[598px] 3xl:max-h-[598px] overflow-hidden  border-none cursor-pointer rounded-[5px]  `}
                      >
                        {index !== hovereDiv ? (
                          <div className="text-white  truncate max-w-[250px] 3xl:max-w-[450px] absolute bottom-[10px] 3xl:bottom-[40px] left-[22px] 3xl:text-[50px] md:text-[18px] text-base not-italic font-semibold  !leading-[45px] tracking-[0.36px] uppercase">
                            {category.name}
                          </div>
                        ) : (
                          <div
                            className={`div2 absolute down-to-up bg-gradient-to-b from-[#e3b67649] to-[#0a4c4bde]  flex flex-col justify-end 3xl:px-10 px-6 rounded-t-sm text-left h-full w-full`}
                          >
                            <div className="text-white text-[14px] 3xl:flex flex-col 3xl:gap-6 max-3xl:gap-3 ">
                              <div className="text-white truncate max-w-[250px]  3xl:max-w-[450px] 3xl:text-[50px] md:text-[18px] text-base not-italic font-semibold  !leading-[45px] tracking-[0.36px] uppercase">
                                {category.name}
                              </div>
                              <span className="3xl:text-[30px] 3xl:!leading-[130%]">
                                {category.description}
                              </span>
                              <p className="text-white text-xs 3xl:text-[50px] lg:text-sm font-[500] !leading-45 tracking-tighter ">
                                View -
                              </p>
                            </div>
                          </div>
                        )}
                        <img
                          className="w-full h-full object-cover"
                          src={category.image ? category.image : Separation}
                          alt="services images"
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="notServiceFound">
                  <p>Results not found</p>
                </div>
              )}
            </div>
          </ToggleTabs>
          {props.categories.queryResult.data.length >= 8 && (
            <CommonButtonWOLink
              disabled={limitValue >= props?.categories?.queryResult?.total}
              handleClick={() => {
                setlimitValue((pre) => pre + 8);
              }}
              variantVal="secondary"
              label="Load more"
            />
          )}
        </div>
        {loginFirst && (
          <LoginAuthFirst hideLoginModal={() => setloginFirst(false)} />
        )}
      </main>
      {/* <main className="main_page">
        <div>
          <Helmet>
            <link
              rel="canonical"
              href={
                serviceName === "prelegate"
                  ? "https://legateca.com/service/prelegate"
                  : "https://legateca.com/service/recommendate"
              }
            />
            <meta name="robots" content="INDEX,FOLLOW" />
          </Helmet>
        </div>
        {serviceName === "prelegate" ? (
          <Helmet>
            <title>
              Lawyer in the UK | family solicitors UK | employment lawyer UK
            </title>

            <meta
              name="title"
              content="Lawyer in the UK | family solicitors UK |  employment lawyer UK"
            />
            <meta
              name="description"
              content="Legateca offers all kinds of lawyer services in the UK, including family solicitors UK, family lawyer UK, employment lawyer UK, and nuptials services in the UK."
            />
          </Helmet>
        ) : (
          <Helmet>
            <title>Looking for an Instant Lawyer?</title>
            <meta name="title" content="Looking for an Instant Lawyer?" />
            <meta
              name="description"
              content="Find a Lawyer Instant, Receive an instant referral to a lawyer for your legal issue, 24 hours a day, seven days a week. Connect with Legateca today!"
            />
          </Helmet>
        )}
        <div className="page-breadcumb-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="wpo-breadcumb-wrap">
                  <h2>
                    {serviceName === "prelegate"
                      ? "Get Started"
                      : "Instant Advice"}
                  </h2>
                  <p className="describe-prligate">
                    {serviceName === "prelegate"
                      ? "Provide your relevant case details as answers to our guided questions. We will deliver a summary at the end, presenting you with further options to explore."
                      : "We constantly train and enhance our AI advisor with a plethora of cases such as yours, to cater to your legal needs instantly with over 80% accuracy - the best so far in the field!"}
                  </p>
                  <ul>
                    <li>
                      <Link to="/home">Home</Link>
                    </li>
                    <li>
                      <span>&nbsp; / &nbsp;</span>
                      <span>
                        {serviceName === "prelegate"
                          ? "Prelegate"
                          : "Recommendate"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="servicesInner-page content_area">
          <div className="container">
            <h2 className="text-center sectionHeading">I am looking for</h2>
            <div className="servicesList-section row">
              {props.categories.isLoading ? (
                <div>Loading...</div>
              ) : !props.categories.isError &&
                !props.categories.isLoading &&
                props.categories.queryResult.total > 0 ? (
                props.categories.queryResult.data.map((category, index) => {
                  return (
                    <div className="col-12 col-md-4 mb-3" key={index}>
                      <div
                        key={category._id}
                        id={category._id}
                        onClick={() =>
                          cardClick(serviceName, category._id, category.name)
                        }
                      >
                        <div className="lawService-card">
                          <div className="cardPicture">
                            <img
                              src={category.image ? category.image : Separation}
                              alt="services images"
                            />
                            <div className="card-content">
                              <h3> {category.name} </h3>
                              <p>{category.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="notServiceFound">
                  <p>Results not found</p>
                </div>
              )}
            </div>
            <p className="describe-prligate"></p>
          </div>
        </section>
        {loginFirst === "Open" && (
          <LoginAuthFirst hideLoginModal={hideLoginModal} />
        )}
      </main> */}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
    categories: state.categories,
    transactionData: state.transactions || {},
    generalData: state["general-data"] || {},
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(Prelegate);

export const ToggleTabs = (props) => {
  let {
    children,
    serviceName,
    setTag,
    tabArray = [],
    setActiveTab,
    activeTab,
  } = props;
  let { tabsFromLawyerProfile } = props; // lawyer profile page
  //Used for tab underline
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [selectedPara, setselectedPara] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setselectedValue] = useState(
    tabsFromLawyerProfile ? tabArray[0].title : "All"
  );

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelect = (id) => {
    setselectedPara(id);
    setActiveTab(id);
    setDropdownOpen(false);
    // window.location.replace(`#heading${id}`);
  };
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);
  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTab];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }
    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTab]);

  return (
    <div
      className={`${
        serviceName === undefined
          ? "xl:max-w-[1280px] 3xl:max-w-[2200px]"
          : "xl:max-w-[1240px] 3xl:max-w-[2200px]"
      } w-full flex items-center flex-col gap-[30px]`}
    >
      <div
        className={`relative hidden lg:block w-full ${
          serviceName === "recommendate" && serviceName != undefined
            ? "max-w-[1066px] 3xl:max-w-[1200px]"
            : serviceName === "lawyer"
            ? "xl:max-w-[494px]"
            : "max-w-[1066px] 3xl:max-w-full"
        } mx-auto`}
      >
        <div
          style={{ justifyContent: serviceName === "lawyer" ? "center" : "" }}
          className="w-full border-b flex border-[#c3deff]"
        >
          {tabArray.map((tab, idx) => {
            return (
              <div
                key={idx}
                ref={(el) => (tabsRef.current[idx] = el)}
                className={`${
                  (
                    tabsFromLawyerProfile
                      ? tab?.title === activeTab
                      : idx === activeTab
                  )
                    ? "text-[#006766] "
                    : "text-[#006766]/80  opacity-50"
                } 3xl:text-[46px] text-base not-italic font-semibold leading-[118.5%] tracking-[1.28px] uppercase cursor-pointer px-[30px] border-none py-[22px]`}
                onClick={() => {
                  if (idx === 0) {
                    setTag && setTag(undefined);
                    if (tabsFromLawyerProfile) {
                      setActiveTab(tab);
                      return;
                    }
                    setActiveTab(idx);
                    return;
                  }
                  let tabCap = `${tab.title
                    .slice(0, 1)
                    .toUpperCase()}${tab.title.slice(1)}`;
                  setTag && setTag(tabCap);
                  if (tabsFromLawyerProfile) {
                    setActiveTab(tab);
                    return;
                  }
                  setActiveTab(idx);
                }}
              >
                {tab.title}
              </div>
            );
          })}
        </div>
        <span
          className="absolute bottom-0 block h-1 bg-[#006766] rounded-tl-lg rounded-tr-lg  transition-all duration-300"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        />
      </div>
      <div className="w-full  lg:hidden">
        <div
          className="flex items-center relative justify-between cursor-pointer p-3 w-full border border-zinc-400 rounded-lg"
          onClick={toggleDropdown}
        >
          <span className="text-[#006766] text-base not-italic font-semibold uppercase cursor-pointer">
            {selectedValue !== null && selectedValue}
          </span>
          <svg
            className={`h-6 w-6 transition-transform duration-300 transform ${
              dropdownOpen ? "rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          {dropdownOpen && (
            <div className="w-full left-0 absolute top-14 bg-white border border-[#bbb] z-10">
              {tabArray.map((it, id) => (
                <a className="!text-decoration-none no-underline">
                  <div
                    key={id}
                    onClick={() => {
                      setselectedValue(it.title);
                      if (id === 0) {
                        setTag && setTag(undefined);
                        // setActiveTab(idx)
                        handleSelect(id);
                        return;
                      }
                      let tabCap = `${it.title
                        .slice(0, 1)
                        .toUpperCase()}${it.title.slice(1)}`;
                      setTag && setTag(tabCap);
                      handleSelect(id);
                    }}
                    className={`font-montserrat block text-text-decoration-none  ${
                      id === selectedPara
                        ? "text-white bg-[#006766]"
                        : "text-[#252525] border-b border-[#bbb]"
                    } cursor-pointer px-[10px] max-3xl:py-4 3xl:px-[40px] 3xl:py-12 text-sm 3xl:text-[30px] not-italic font-light !leading-[149%] 3xl:!leading-[130%] tracking-[0.28px]`}
                  >
                    {`${id + 1}. ${it.title}`}
                  </div>
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
