import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, withRouter } from "react-router-dom";
import Modal from "react-modal";
import LoginPage from "../Pages/LoginPage";
import Logo from "./../assets/images/legateca-logo.png";
import { compose } from "recompose";
import { connect } from "react-redux";
import { feathersAuthentication } from "../server/feathers-binding";
import { ToastContainer } from "react-toastify";
import Avtar from "./../assets/images/team1.jpg";
// import UserProfile from "./no-img.png";
import UserProfile from "./../assets/images/profilePicture.svg";
import UserProfile1 from "./../assets/images/profilePicture1.svg";
import logowhite from "./../assets/images/legateca-logo-white.png";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// import profilePicture from "./../assets/images/profilePicture.svg";
const Header = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [one, setOne] = useState(true);
  const [HeaderOpen, setHeaderOpen] = useState("");
  const [profilePic, setProfilePic] = useState(UserProfile);
  const location = useLocation();
  const param = useParams()
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const pathName = parts[parts.length - 1];


  function userProfile() {
    if (!props.user?.profile_img || props.user?.profile_img === '') {
      return '/no-img.png'
      // return UserProfile
    }
    return props.user?.profile_img
  }
  useEffect(() => {
    if (props.user.role === "user") {
      // setProfilePic(props.user.profile_img);
    }
    if (props.userData.role === "user") {
      // setProfilePic(props.userData.profile_img);
    }
  }, [props.user, props.userData]);

  const HeaderToggle = () => {
    if (HeaderOpen === "") {
      setHeaderOpen("HeaderToggle");
    } else {
      setHeaderOpen("");
    }
  };
  const closeHeader = () => {
    setHeaderOpen("");
  };
  let article
  let validatePage = ['about', 'partner', 'faq', 'contact-us', 'privacy-policy', 'terms-and-conditions', 'login', 'send-code', 'chnage-password', 'change-password', 'signup',
    "profile", 'prelegate', 'recommendate', 'lawyer', 'articles', `${param.id}`, 'mediate', `${param?.serviceName}`, 'lawyer-profile', 'payment',].includes(pathName)
  return (
    <>
      {/* {pathName === "home" || pathName === "" ? ( */}
      {pathName === "home" || pathName === "" || validatePage || param?.subCategoryId !== undefined ? (
        <header className="main_header" style={{
          margin: `/service/prelegate/${param?.subCategoryId}`.includes(pathname) ? '0 ' : '',
          padding: `/service/prelegate/${param?.subCategoryId}`.includes(pathname) ? '0 20px' : '',
          // background: param?.subCategoryId !== undefined ? '#006766' : '',
          width: `/service/prelegate/${param?.subCategoryId}`.includes(pathname) ? '100%' : ''
        }}>
          {/* <ToastContainer/> */}
          <div className="innerHeader">
            <div className="logo">
              <div className="header_logo">
                <Link to="/">
                  <img className="logo" src={logowhite} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="header_navigaion">
              <nav className="navbar navbar-expand-lg navbar-light">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={HeaderToggle}
                >
                  <span className="toggler-icon">
                    <i className="fas fa-bars"></i>
                  </span>
                </button>
                <div
                  className={`navbar-collapse ${HeaderOpen}`}
                  id="HeaderToggle"
                >
                  <span className="closeNavigation" onClick={closeHeader}>
                    <i className="fa fa-times"></i>
                  </span>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink
                        activeClassName="activeIndex"
                        to="/home"
                        className="nav-link"
                        style={{
                          color:
                            pathName === "home" || pathName === ""
                              ? "#e4b576"
                              : "white",
                          transition: "color 0.3s",
                        }}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        style={{ color: "white", transition: "color 0.3s" }}
                        onMouseOver={(e) => (e.target.style.color = "#e4b576")}
                        onMouseOut={(e) => (e.target.style.color = "white")}
                        to="/about"
                        className="nav-link"
                        activeClassName="activeIndex"
                      >
                        About us
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                    <NavLink activeClassName="" to="" className="nav-link">Our Team</NavLink>
                  </li> */}
                    <li className="nav-item dropdown">
                      <NavLink
                        style={{ color: "white", transition: "color 0.3s" }}
                        onMouseOver={(e) => (e.target.style.color = "#e4b576")}
                        onMouseOut={(e) => (e.target.style.color = "white")}
                        activeClassName="activeIndex"
                        to="/services"
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Features
                      </NavLink>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service/prelegate"
                          >
                            Get Started
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service/recommendate"
                          >
                            Instant Advice
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/lawyer">
                            Find a Lawyer
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/mediate">
                            Mediation
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li className="nav-item">
                    <NavLink activeClassName="activeIndex" to="/contact-us" className="nav-link">Contact Us</NavLink> 
                  </li> */}
                    <li className="nav-item">
                      <NavLink
                        style={{ color: "white", transition: "color 0.3s" }}
                        onMouseOver={(e) => (e.target.style.color = "#e4b576")}
                        onMouseOut={(e) => (e.target.style.color = "white")}
                        activeClassName="activeIndex"
                        to="/articles"
                        className="nav-link"
                      >
                        Blogs & Articles
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeClassName="activeIndex"
                        to="/contact-us"
                        className="nav-link"
                        style={{ color: "white", transition: "color 0.3s" }}
                        onMouseOver={(e) => (e.target.style.color = "#e4b576")}
                        onMouseOut={(e) => (e.target.style.color = "white")}
                      >
                        Contact us
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            {/* <div className="navigation_action align-middle"> */}
            {/* {props.user && (
              <div
                onClick={() => {
                  props.logout();
                  props.history.push("/") 
                }}
                className="getStarted"
              >
                Logout
              </div>
            )} */}
            {!props.user && (
              <Link
                to={{ pathname: "/login", state: "/home" }}
                className="getStarted align-middle"
              >
                Login/Signup
              </Link>
            )}
            {props.user && (
              <>
                {/* <li className="nav-item">
                      <NavLink activeClassName="activeIndex" to="/dashboard/settings" className="nav-link">Settings</NavLink>
                    </li> */}

                <div className="nav-item flex items-center ">
                  {
                    <NavLink
                      activeClassName="activeIndex"
                      to="/dashboard/profile"
                      // className="nav-link min-w-[46px] min-h-[46px] max-w-[46px] max-h-[46px]"
                      className="w-[46px] rounded-full h-[46px] overflow-hidden "
                    >
                      {/* <div className="userAvtar">  */}
                      {/* <div
                      className=" "
                    className="userAvtar"
                    style={{ color: "", marginTop: "12px" }}
                    > */}
                      <img
                        className="w-full   h-full object-cover "
                        // src={profilePic ? profilePic : UserProfile}
                        src={userProfile()}
                        alt="Avatar"
                      />
                      {/* </div> */}
                    </NavLink>
                  }
                </div>
                {/* {props.userData.profile_img ? (
                    <NavLink
                      activeClassName="activeIndex"
                      to="/dashboard/profile"
                      className="nav-link"
                    >
                      <div className="userAvtar">
                        <img
                          src={profilePic ? profilePic : UserProfile}
                          src={profilePic ? profilePic : UserProfile}
                          alt="Avtar"
                        />
                      </div>
                    </NavLink>
                  ) : (
                    <NavLink
                      activeClassName="activeIndex"
                      to="/dashboard/profile"
                      className="nav-link"
                    >
                      <div
                        className="userAvtar"
                        style={{ color: "", marginTop: "12px" }}
                      >
                        <img
                          src={profilePic ? profilePic : UserProfile}
                          alt="Avatar"
                        />
                      </div>
                    </NavLink>
                  )} */}
                {/* </div> */}
              </>
            )}
            {/* </div> */}
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {
              setIsOpen(false);
            }}
            contentLabel="Example Modal"
          >
            <LoginPage
              closePopup={() => setIsOpen(false)}
              setIsOpen={setIsOpen}
            />
          </Modal>
        </header>
      ) : (
        <header className="main_header_old">
          {/* <ToastContainer/> */}
          <div className="innerHeader_old container">
            <div className="header_logo">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className="header_navigaion_old">
              <nav className="navbar navbar-expand-lg navbar-light">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={HeaderToggle}
                >
                  <span className="toggler-icon">
                    <i className="fas fa-bars"></i>
                  </span>
                </button>
                <div
                  className={`navbar-collapse ${HeaderOpen}`}
                  id="HeaderToggle"
                >
                  <span className="closeNavigation" onClick={closeHeader}>
                    <i className="fa fa-times"></i>
                  </span>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink
                        activeClassName="activeIndex"
                        to="/home"
                        className="nav-link"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeClassName="activeIndex"
                        to="/about"
                        className="nav-link"
                      >
                        About
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
              <NavLink activeClassName="" to="" className="nav-link">Our Team</NavLink>
            </li> */}
                    <li className="nav-item dropdown">
                      <NavLink
                        activeClassName="activeIndex"
                        to="/services"
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Features
                      </NavLink>

                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service/prelegate"
                          >
                            Get Started
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service/recommendate"
                          >
                            Instant Advice
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/lawyer">
                            Find a Lawyer
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/mediate">
                            Mediation
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeClassName="activeIndex"
                        to="/articles"
                        className="nav-link"
                      >
                        Blog & Articles
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeClassName="activeIndex"
                        to="/contact-us"
                        className="nav-link"
                      >
                        Contact us
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
              <NavLink activeClassName="activeIndex" to="/contact-us" className="nav-link">Contact Us</NavLink> 
            </li> */}
                    {props.user && (
                      <>
                        {/* <li className="nav-item">
                <NavLink activeClassName="activeIndex" to="/dashboard/settings" className="nav-link">Settings</NavLink>
              </li> */}
                        <li className="nav-item">
                          {props.userData.profile_img ? (
                            <NavLink
                              activeClassName="activeIndex"
                              to="/dashboard/profile"
                              className="nav-link"
                            >
                              <div className="userAvtar">
                                <img
                                  src={profilePic ? profilePic : UserProfile1}
                                  alt="Avtar"
                                />
                              </div>
                            </NavLink>
                          ) : (
                            <NavLink
                              activeClassName="activeIndex"
                              to="/dashboard/profile"
                              className="nav-link"
                            >
                              <div className="userAvtar">
                                <img
                                  src={profilePic ? profilePic : UserProfile1}
                                  alt="Avtar"
                                />
                              </div>
                            </NavLink>
                          )}
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </nav>
              <div className="navigation_action_old">
                {!props.user && (
                  <Link
                    to={{ pathname: "/login", state: "/home" }}
                    className="getStarted_old"
                    style={{
                      borderRadius: "5px",
                      background: "#E3B576",
                      display: "inline-flex",
                      padding: "9px 28px",
                      alignItems: "center",
                      color: "white",
                      gap: "21px",
                      fontWeight: 500,
                      textDecoration: "none",
                      transition: "background-color 0.3s", // Add transition for smooth color change
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#006766")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#E3B576")
                    }
                  >
                    Login
                  </Link>
                )}
              </div>
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {
              setIsOpen(false);
            }}
            contentLabel="Example Modal"
          >
            <LoginPage
              closePopup={() => setIsOpen(false)}
              setIsOpen={setIsOpen}
            />
          </Modal>
        </header>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || false,
    userData: (state.users && state.users.data) || {},
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout: feathersAuthentication.logout })
)(Header);
